/*      light colors         */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

/* Common Scroll Bar */
.scrollable {
  /* position: relative; */
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #AAB7CF transparent;
  -webkit-overflow-scrolling: touch;
}

/* End Common Scroll Bar */

* {
  /* outline: 2px solid limegreen !important; */
}


.landing_nav {
  height: 100vh;
  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.landing_nav a {
  margin: 0 10px;
  font-size: 20px;
}

.landing_nav a:hover {
  text-decoration: underline;
}

.list-errors {
  padding: 14px;
  border-radius: 4px;
  border: 2px solid #F2105A;
  display: flex;
  flex-direction: column;
  color: #F2105A;
  margin: 10px auto;
  width: 100%;
}

.list-errors__item {
  color: #F2105A;
}

.list-errors__item:first-letter {
  text-transform: uppercase;
}

.error-text {
  color: red;
}

.ReactTable {
  border: none;
}

.ReactTable * {
  border: none !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-tr {
  background-color: #447df7;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  background-color: #447df7;
  color: #ffffff;
  font-weight: 900;
  text-align: center;
  font-size: 14px;
}

.ReactTable .rt-tbody {
  overflow: hidden;
}

.ReactTable .rt-tbody .rt-tr-group {
  margin-top: 5px;
  max-height: 40px;
}

.ReactTable .rt-tbody .rt-tr {
  max-height: 40px;
}

.ReactTable .rt-tbody .rt-tr.-even {
  background-color: #ffffff;
}

.ReactTable .rt-tbody .rt-tr.-even:hover {
  background-color: #ffffff !important;
}

.ReactTable .rt-tbody .rt-tr.-odd {
  background-color: #fff;
}

.ReactTable .rt-tbody .rt-tr.-odd:hover {
  background-color: #fff !important;
}

.ReactTable .rt-tbody .rt-td {
  text-align: center;
  color: #7c7c7c;
  font-size: 12px;
  font-weight: 600;
}

.ReactTable .pagination-bottom .-pagination {
  box-shadow: none;
}

.status-box {
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  padding: 2px 10px;
  min-width: 70px;
}

.status-box_active {
  background-color: #d3e8d5;
  color: #4fbf58;
}

.status-box_inactive {
  background-color: #f6cecf;
  color: #cc6d6b;
}

.status-box_default {
  background-color: rgb(200, 200, 200);
}

.status-box_invited {
  background-color: #ffa534;
  color: #FFFF00;
}

.status-box_pending {
  background-color: #ffa534;
  color: #FFFF00;
}

.status-box_approved {
  background-color: #d3e8d5;
  color: #4fbf58 !important;
}

.status-box_rejected {
  background-color: #f6cecf;
  color: #cc6d6b !important;
}

.status-box_approved {
  background-color: #d3e8d5;
  color: #4fbf58 !important;
}

.status-box_rejected {
  background-color: #f6cecf;
  color: #cc6d6b !important;
}

.status-box_sick_leave {
  background-color: #ffa534;
  color: #FFFF00;
}

.status-box_swap_shift {
  background-color: #99d9fd;
  color: #2d7a8d !important;
}

.header-meta {
  margin-bottom: 20px;
}

.header-meta button {
  font-weight: 600;
  margin-right: 10px;
}

.header-meta button:last-child {
  margin-right: 0;
}

.radio-widget {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  align-items: center;
}

.radio-widget .radio {
  margin: 5px;
}

.radio-widget.vertical {
  display: block;
}

.custom-layout-class .col-xs-offset-9 {
  margin-left: 75%;
}

.custom-layout-class .array-item {
  display: flex;
  flex-wrap: wrap;
}

.custom-layout-class .col-xs-9 {
  flex: 0 0 auto;
  width: 75%;
}

.custom-layout-class .col-xs-3 {
  flex: 0 0 auto;
  width: 25%;
}

.custom-layout-class .array-item-toolbox .btn-group {
  justify-content: end !important;
}

.custom-layout-class .array-item-toolbox .btn-group>.btn {
  position: relative;
  flex: none !important;
}

.custom-layout-class .array-item-add button:focus,
.custom-layout-class .array-item-add button:hover {
  color: #2769f6 !important;
  border-color: #2769f6 !important;
}

.custom-layout-class .array-item-toolbox .array-item-remove:hover,
.custom-layout-class .array-item-toolbox .array-item-remove:focus {
  background-color: transparent !important;
  color: #fa1825 !important;
  border-color: #fa1825 !important;
}

.custom-layout-class .array-item-toolbox .array-item-move-up:hover,
.custom-layout-class .array-item-toolbox .array-item-move-up:focus {
  background-color: transparent !important;
  color: #2550AC !important;
  border-color: #2550AC !important;
}

.custom-layout-class .array-item-toolbox .array-item-move-down:hover,
.custom-layout-class .array-item-toolbox .array-item-move-down:focus {
  background-color: transparent !important;
  color: #2550AC !important;
  border-color: #2550AC !important;
}

.custom-layout-class .field-array-of-object .array-item-list .array-item {
  margin-top: 10px;
  padding-top: 10px;
}

.custom-layout-class .field-array-of-object .field-object fieldset {
  display: flex;
  flex-wrap: wrap;
}

.custom-layout-class .field-array-of-object .field-object .form-group {
  padding: 0px 10px !important;
}

.list-filters {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* background: #ffffff; */
  border-radius: 35px;
  margin: 15px 0 27px 0;
}

.list-filters button {
  margin-left: 5px;
}

.list-filters button:first-child,
.list-filters button:last-child {
  margin-left: 0;
}

.list-filters .form-control {
  width: auto;
  margin: 0 15px;
  flex-grow: 1;
  border-radius: 5px;
}

.table-list__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.table-list__header_contract {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: -40px;
}

.ckContracts {
  display: flex;
  justify-content: center;
  -webkit-print-color-adjust: exact;

}

.ckContracts .ck.ck-editor,
.ck-contracts .ck.ck-editor {
  width: 220mm;
  -webkit-print-color-adjust: exact;

}

/* .ck-contracts .ck.ck-content {
  border: none;
} */
.fa_custom {
  color: #007FFF
}

.submit_contract {
  display: flex;
}

.close-button {
  color: black;
  float: right;
  font-size: 30px;
}

.example::-webkit-scrollbar {
  display: none;
}

.example {
  scrollbar-width: none;
}

.employeeContractMain p:hover {
  color: #447df7 !important;
}

.employeeContractMain p {
  color: grey;
  padding-top: 10px;
  cursor: pointer;
  text-align: right;
}

.employeeContractMain h5 {
  padding-left: 50px;
  font-weight: bolder;
  padding-top: 20px;
  ;
}

.employeeContractMain h4 {
  text-align: center;
  margin-top: 100px;
  font-weight: bolder;
  color: red;
}

.contractNameField {
  border: 1px solid rgb(206, 204, 204);
  height: 40px;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0.5em;
}


.table-list__header button {
  margin: 5px !important;
}

.table-list__header_contract button {
  margin: 5px;
  margin-bottom: 60px;
}

.primary-page {
  padding: 0 15px;
  box-sizing: border-box;
}

.recipient_total {
  margin-right: 60px;
}

.project-statuses {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
}

.project-statuses .radio {
  padding: 5px;
  margin: 0;
}

.managers-widget {
  min-height: 46px;
  align-items: center;
  width: 100%;
}

.checkbox label,
.radio label {
  user-select: none;
}

.checkelistementcrow {
  /* width: 100%;
  margin-bottom: 15px;
  position: relative;
  display: flex; */

  position: relative;
  /* color: #aaa; */
  font-size: 16px;
  /* display: inline-block; */
}

.checkelistementc {
  /* width: 100%;
  margin-bottom: 15px;
  position: relative;
  display: flex; */

  position: relative;
  color: #fcfcfc;
  font-size: 16px;
  cursor: cell !important;
  /* display: inline-block; */
}

.checklisticonc {
  /* position: absolute;
  top: 8px;
  right: 10px; */

  position: absolute;
  top: 0px;
  padding: 0.4rem;
  right: -6px;
  background-color: #27518D;
  color: white;
  width: 1.3em !important;
  height: fit-content;
  border-radius: 2em;
}

.elementiconc {
  position: absolute;
  right: 0px;
  color: #27518D;
  width: 1.3em !important;
  height: fit-content;
  font-size: 20px;
}

.checklistformcontrolc {
  /* width: 100%;
  padding: 10px;
  outline: none; */

  width: 250px;
  height: 32px;
  background: #fcfcfc;
  border: 2px solid #27518D !important;
  border-radius: 1em !important;
  box-shadow: 0 0 2px #ccc, 0 3px 6px #ebebeb inset !important;
  text-indent: 5px;
  padding-right: 30px;
  box-sizing: border-box;
}

.elementtreerowlabel {
  white-space: nowrap;
  width: 100%;
  height: 36px;
  background: #e4e4ff;
  text-indent: 5px;
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}

.elementformcontrolc {
  color: #2550AC;
  white-space: nowrap;
  height: 32px;
  text-indent: 5px;
  padding-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
}

.elementtreechecklist {
  padding-left: 10px;
  display: inline;
}

.elementlistc {
  padding: 3em 0em;
}

.elementgridc {
  padding: 0.5em 0.5em;
}

.elementstripe {
  background-color: rgba(0, 0, 0, .03);
}

.checklistshortcuticonc {
  /* padding: 0em 1.5em; */
  justify-content: center;
  margin: 0em 0.6em;
}

.checklist-comment {
  /* margin: 5px 0px; */
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
}

.checklist-main {
  background-color: #f5f5f5;
  /* margin: 12px 0 30px 34px; */
  margin: 5px;
  padding: 19px 19px 31px 25px;
}

.checklist-category-name {
  display: inline-flex;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  text-decoration: underline;
}

.checklist-subcategory {
  border: solid 1px #e2e2e2;
  background-color: #ffffff;
  padding: 22px 20px 24px;
  /* margin: 25px 6px 0 0; */
  margin: 5px 6px 0 0;
}

.signModal_Note {
  color: grey;
  font-size: 14px;
  display: flex;
  justify-content: center !important;
  margin-top: 10px;
}

.checklist-subcategory-name {
  display: inline-flex;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  text-decoration: underline;
}

.checklist-element {
  /* margin: 25px 0 10px; */
  margin: 5px 0 0;
  padding: 15px 17px 15px 5px;
  border: solid 1px #1783ff;
  background-color: #ffffff;
  width: 100%;
}

.checklist-element-name {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1783ff;
  cursor: pointer;
}

.checklist-attachment-link {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d6bff;
  cursor: pointer;
}

.checklist-element-bottombar {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 5px;
}

.checklist-element-bottombar-div {
  margin-right: 15px;
}

.checklist-element-done {
  font-weight: 600;
  color: #36de0e;
}

.checklist-element-new {
  font-weight: 600;
  color: #1783ff;
}

.checklist-element-attachment {
  width: 600px;
  margin: 10px 0px
}

.checklist-element-pdf-attachment {
  margin: 10px 0px;
}

.checklist-element-attachment-checkmodel {
  width: 300px;
  margin: 10px 0px
}

.subcatagorydiv {
  background-color: #D19714;
  border-radius: 1em;
}

.subcatagoryiconc {
  /* display: flex !important; */
  color: #fff;
  margin: 0em 0.3em;
  vertical-align: text-bottom;
}

.addphotodiv {
  border-radius: 1em;
  background-color: #fff;
  border: 2px solid black;
}

.addphotoiconc {
  color: #515151;
  margin: 0.2em 0.3em;
  vertical-align: text-bottom;
}

.addcommentdiv {
  background-color: #264F8F;
  border-radius: 1em;
}

.addcommenticon {
  /* display: flex !important; */
  color: #fff;
  margin: 0em 0.3em;
  vertical-align: text-bottom;
}


.deletediv {
  background-color: #C23C28;
  border-radius: 1em;

}

.deleteicon {
  /* display: flex !important; */
  color: #fff;
  margin: 0em 0.3em;
  vertical-align: text-bottom;

}

@media screen and (max-width: 876px) {
  .list-filters {
    border-radius: 14px;
    justify-content: center;
  }

  .list-filters button {
    margin: 5px !important;
    flex-grow: 1;
  }

  .list-filters .form-control {
    margin: 5px !important;
    width: 100%;
  }
}


@media only screen and (min-width: 700px) and (max-width: 1366px) {
  /* .modal-dialog {
    width: 900px;
    margin: 30px auto;
  } */

  .signatureWidgetModal .modal-dialog {
    width: 700px !important;
    margin: 30px auto !important;
  }

}

@media only screen and (min-width: 769px) and (max-width: 949px) {

  .AgreementModal .language-container {
    margin-left: 350px !important;
    margin-top: -23px !important;
    display: flex !important;
  }

}

@media only screen and (min-width: 550px) and (max-width: 690px) {

  .AgreementModal .language-container {
    margin-left: 350px !important;
    margin-top: -23px !important;
    display: flex !important;
  }

}

@media only screen and (min-width: 200px) and (max-width: 550px) {

  .AgreementModal .language-container {
    margin-left: 0px !important;
    margin-top: 10px !important;
    display: flex !important;
  }

}



.panel-group {
  margin-top: 30px;
}


.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.panel-close {
  max-height: 0;
}


.subpanelgroupc {
  margin-top: 0;
  margin-bottom: 0;
}

.checklistelementiconc {
  margin: "0.3em 0.5em 0em 0.2em !important"
}


* {
  box-sizing: border-box
}

/* Set height of body and the document to 100% */
body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial;
}

/* Style tab links */

.tabcontent {
  color: white;
  display: none;
  padding: 100px 20px;
  height: 100%;
}

/* Style tab links */

.elementdetails {
  margin-top: -1em;
}

.elementlistingdiv {
  padding: 0em 1.5em;
  max-height: 70vh;
  height: auto;
  overflow-y: scroll;
}


.a4pageStyle {
  width: 21cm;
  height: 29.7cm;
  padding-left: 25mm;
  padding-right: 25mm;
  margin: auto;
  /* margin-bottom: 10px; */
  /* padding: 30mm 45mm 30mm 45mm; */
}

.a4pageStylePrint {
  /*can be used while printing through iframe*/
  display: none;
}


@media print {
  .a4pageStylePrint {
    display: block;
    width: 21cm;
    height: 29.7cm;
    margin: 25mm;
  }
}

.reactqillParent>.ql-container {
  border: none !important;
}

.schedule {
  margin: 15px 0 20px 0;
  padding: 10px;
}

.schedule-header {
  font-size: 24px;
  line-height: 1;
  text-align: left;
  color: #3d3d3d;
}

.schedule-text {
  font-size: 15px;
  line-height: 1.6;
  text-align: left;
  color: #919191;
}

.schedule-options {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
}

.visitor-registration-msg {
  margin: 20px 0 0;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}

.visitor-registration-tnc {
  margin: 20px 0 0;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #7d7d7d;
}

/* .custom-btn-lg{
  margin: 5px 10px;
  height: 5rem;
  font-size: 18px!important;
} */

.visitor-client-register {
  background-color: #fff;
  overflow-x: hidden;
  height: 100vh;
}

.remove-btn {
  color: #4b5155;
}

.attachment-tbl th {
  background-color: #447df7;
  padding: 10px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 15px;
}

.attachment-tbl>thead>tr>th {
  font-size: 15px !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  padding: 10px !important;
  text-align: center;
}

.attachment-tbl>tbody>tr>td {
  border-color: #FFFFFF !important;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.ReplaceModal .modal-dialog {
  width: 700px !important;
  margin: 30px auto !important;
}

.control-panel {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 900px) {
  .control-panel {
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
  }
}

.d-flex {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.tip-custom-card {
  margin-top: 2em;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: end;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.table-list__header_v2{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.header_text{
  font-size: large;
  font-weight: 600;
  color: #777;
}

.primary-page_title_weekly{
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: center;
}
.table-list__header_v3{
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; */
}
.table-list__header_v3 .jc_c{
  text-align: center;
}
.table-list__header_v3 .display__flex{
  display: flex;
}
.table-list__header_v3 .align__items__center{
  align-items: center;
}
.table-list__header_v3 .text__align__end{
  text-align: end;
  font-size: medium;
}
/* .notificationModal .modal-content{
  width:600px !important;
  margin-left: 200px !important;
  margin-top: 150px !important;
  height:230px !important;
} */
.modal-600w {
  width:600px !important;
}


.scrollable::-webkit-scrollbar {
  width: 6px;
  margin: 0 15px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dbe5ed; 
  border-radius: 10px;  
}
 
/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background-color: #e4e5e5; 
  border-radius: 10px;
}

.customYScrollBar{
  overflow-y: scroll;  
}

.maxHeight-50vh{
  max-height: 50vh ;
}

.maxWidth-100vh{
  max-width: 100vh;
}

.fw-600{
  font-weight: 600;
}

.text-capitalize{
  text-transform: capitalize;
}

.text-uppercase{
  text-transform: uppercase;
}

.floatLeft{
  float: left;
}

.maxWidth-50{
  max-width: 50%;
}

.text__align__start{
  text-align: start;
}

.LoadingSpinnerClass{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 20px;
  background-color: rgba(230, 224, 224, 0.5);
  z-index: 2;
}
.mt-20{
  margin-top: 20px;
}
.text-success{
  color:green !important;
}

.style-show-more{
  color: #4b5155;
  font-size: 11px;
}
.status-box_invited {
  background-color: #ffa534;
  color: #FFFF00;
}
.status-box_pending {
  background-color: #ffa534;
  color: #FFFF00;
}
.public_form_user_register input{
  min-height: 46px;
}


.module-name-contract .css-1owrxwi-control {
  background-color: white !important;
  pointer-events: auto ! important;
  cursor: not-allowed ! important;
}

.contactform-table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
}

.footernav {
  display: flex;
  margin-top: -20px;
  justify-content: space-between;
  align-items: center;
}

.footernav .lp-text-btn {
  color: #9a9a9a !important;
  margin-top: 15px !important;
}

.footernav .dropdown-menu::marker {
  display: none;
}

.nav-signer .navbar {
  font-size: 1px !important;
}

.btn-sign_doc_name {
  max-width: 80% !important;
  min-width: 80% !important;
}

.subs_w-35 {
  width: 35%;
}

.subs_w-37 {
  width: 37%;
}

.floatR_MarginB_30_MarginR_0{
  float: right !important;
  margin-bottom: 30px;
  margin-right: 0;
}
.ml-30 {
  margin-left: 30px;
}

.ml-10 {
  margin-left: 10px;
}

@media screen and (max-width: 876px) {
  .subs_w-35 {
    width: 70%;
  }

  .subs_w-37 {
    width: 76%;
  }
}

.text-align-left{
  text-align: left !important;
}
.text-center {
  text-align: center;
}

.shiftFormModal .modal-content {
  max-height: 600px;
  overflow: scroll;
}

.shiftFormModal ::-webkit-scrollbar {
  display: none;
}

/*styles for timelog list and sidebar --begin*/
/*sideBar changes goes here */

.new-sidebar-class .sidebar-wrapper .nav {
  flex-direction: column;
  transition: 0s;
}

.new-sidebar-class .sidebar:after,
.bootstrap-navbar::after {
  background-color: #F7F9FD !important;
  background: #F7F9FD !important;
}

.new-sidebar-class .sidebar .nav li>a {
  color: black !important;
  padding-top: 20px;
  padding-right: 0 !important;
  margin: 0 !important;
}

.new-sidebar-class .sidebar .nav li a:focus {
  background: #E6EAF2;
  position: relative;
}

.new-sidebar-class .sidebar .nav li a:hover {
  background: #E6EAF2;
  position: relative;
}

.new-sidebar-class .sidebar .nav li.active .mainNavLink {
  background: #E6EAF2;
  position: relative;
}

.new-sidebar-class .nav li.active .mainNavLink {
  background: #E6EAF2;
  position: relative;
}

.new-sidebar-class .sidebar .nav li a:focus:before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 55px;
  left: 0;
}

.new-sidebar-class .sidebar .nav li.active .mainNavLink:before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 55px;
  left: 0;
}

.new-sidebar-class .nav li.active .mainNavLink:before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 40px;
  left: 0;
}

.new-sidebar-class .sidebar .nav li a:hover:before {
  content: '';
  background: #222aa4;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 55px;
  left: 0;
}


.new-sidebar-class .sidebar .nav li>a>span>div {
  padding-left: 30px;
  font-weight: bolder;
  display: flex;
  font-size: 16px;
}

.new-sidebar-class .sidebar .nav li>a>span {
  padding-left: 25px;
}

.new-sidebar-class .sidebar .nav li>a>span>div>.caret {
  /* display: inline-block; */
  margin-left: 2.255em;
  vertical-align: -0.745000000000001em;
  margin-top: 7px;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  color: #9BA7BF !important;
  height: 0;
  width: 0;
  position: absolute;
  right: 0;
  margin-right: 15px;
}

.new-sidebar-class .chat-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EE2222;
  border-radius: 50%;
  width: 16px;
  height: 15px;
  /* line-height: 15px; */
  color: white;
  font-size: 9px;
  padding: 0px;
}

.sideberhidee {
  visibility: hidden;
}

.sidebarmy {
  visibility: visible;
}

@media (max-width: 992px) {

  .nav-open .navbar .container,
  .nav-open .main-panel,
  .nav-open .wrapper-full-page {
    transform: none !important;
  }

  .sidebar,
  .bootstrap-navbar,
  .off-canvas-sidebar .navbar-collapse {
    transition: none !important;
  }
}

@media (max-width: 992px) {

  /* .nav-open .sidebar, .nav-open .bootstrap-navbar {
      transform: translate3d(0px, 0, 0);
      left: 0;
      transition: none !important;
  } */
  .nav-open .sidebar,
  .nav-open .bootstrap-navbar {
    visibility: hidden;
  }
}

.background-color-2550AC {
  background-color: #2550AC !important;
}

.background-color-FFFFFF {
  background-color: #FFFFFF !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.margin-right-10 {
  margin-right: 10px;
}

.tableMain th span {
  display: inline-block;
  margin-left: 4px;
}

.tableMain .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #D9D9D9 !important;
  color: #1B1B1B;
}

.tableMain .table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-accent-bg: #F4F1F1;
  color: #1B1B1B;
}

.tableMain td {
  font-size: 14px;
  color: #1B1B1B;
  font-weight: 500;
  border: 0;
  padding: 0.5rem 0.8rem;
  text-align: center;
  vertical-align: middle;
}

.tableMain .form-check {
  margin: 0;
  min-height: 25px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.tableMain .form-check .form-check-input {
  width: 25px;
  height: 25px;
  margin-top: 0;
  background-color: #D9D9D9;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  margin: 0;
}

.tableMain .form-check-input:checked {
  background-color: #2550AC;
}

/* .manual-status{
  width: 103px;
height: 26px;
background: rgba(37, 80, 172, 0.15);
border-radius: 6px;
padding: 9px;
} */
.btnsidebar {
  width: 22px;
  height: 22px;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}


#minimizeSidebar {
  background: none;
  display: flex;
}

#minimizeSidebar i {
  color: #2550AC !important;
  /* font-size: 24px; */
}

#minimizeSidebarClose {
  display: none;
  background: none;
  /* width: 22px; */
  /* height: 22x; */
}

#minimizeSidebarClose i {
  color: #2550AC !important;
  /* font-size: 20px; */
}


.btnsidebar:hover {
  background-color: #2550AC !important;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #fff !important;
  outline: none !important;
  /* border: 2px solid #fff  !important; */
}

#minimizeSidebarClose:hover i {
  color: #fff !important;
}

#minimizeSidebar:hover i {
  color: #fff !important;

}

.logo .logo-normal {
  text-align: center;
}

.logomain {
  display: flex !important;
  justify-content: center;
  /* margin: 20px; */
  margin: 0px 20px;
  /* width: 100%;
  height: 100%; */
}

.adminNavButtons {
  color: black !important;
}

.logo .adminNavButtons:hover {
  background-color: transparent !important;
  color: black !important;
}

.new-sidebar-class li a:hover .sidebarIcon {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(15);
}

.new-sidebar-class li a:focus .sidebarIcon {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(14);
}

.new-sidebar-class li.active .mainNavLink .sidebarIcon {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(14);
}

.login_modal .modal-body {
  position: relative;
  padding: 0px 15px;
}

@media (max-width:767px) {
  .login_modal .modal-dialog {
    position: relative;
    width: auto;
    margin: 70px 0px 0px 0px;
  }

  .login_modal .modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    outline: 0;
  }

  .login_modal .modal-content .logo {
    position: absolute;
    top: -125px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .login_modal .modal-dialog {
    width: 30em;
  }

  .login_modal .modal-content .logo {
    display: none;
  }

  .login_modal .modal-body {
    padding: 0px 15px;
  }
}

.loginTitle {
  font-weight: 500;
}

.loginFooter {
  color: #0066CC;
  text-align: center;
  justify-content: center !important;
}

.loginFooter button {
  border: none;
  background: none;
  border-bottom: 2px solid #0066CC;
  color: #0066CC;
}

.signup_modal {
  padding-left: 0px;
}

.login-container .loginbutton {
  background-color: #0071E3;
  color: #fff;
  opacity: 1;
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  border: none;
}

.login-container .loginbutton:hover {
  background-color: #0071E3 !important;
  color: #fff !important;
  border: none;
}

.login-container .text_area {
  height: 140px;
  width: 100%;
}

.forogottext {
  color: #1D1D1F;
}

.forgot-email-sent {
  margin: 20px;
  color: white;
  background: #2c6cf6;
  border: 2px solid aliceblue;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.login-container .row {
  margin-bottom: 20px;
}

.login-container .row p {
  margin-top: 10px;
}

/* .login-container .big-text{
  font-weight: 600 !important;
} */
@media (max-width: 1200px) {

  .sidebar,
  .bootstrap-navbar,
  .off-canvas-sidebar .navbar-collapse {
    visibility: hidden !important;
  }

  .main-panel {
    width: 100% !important;
  }
}

.sidebarcanvas {
  width: 260px !important;
}

.sidebarcanvasbody {
  padding: 0 !important;
}

.sidebarcanvas {
  background-color: white;
}

.sidebarMobile ul li a div {
  padding-left: 30px;
  color: #1B1B1B;
  font-weight: 600;
}

.sidebarMobile .sidebar-wrapper {
  overflow: auto;
  position: relative;
  z-index: 4;
  padding-bottom: 30px;
  background-color: #fff;
}

.sidebarMobile .logo {
  background-color: white;
}

.sidebarMobile ul li a span {
  padding-left: 25px;
  color: #1B1B1B;
  font-weight: 600;
}

.sidebarMobile ul li {
  margin-top: 15px;
}

.sidebarMobile .nav>li>a:hover,
.nav>li>a:focus {
  background: #E6EAF2;
  position: relative;
}

.sidebarMobile .nav>li>a:hover,
.nav>li>a:focus {
  background: #E6EAF2;
  position: relative;
}

.sidebarMobile li a:focus:before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: 2%;
  width: 7px;
  height: 40px;
  left: 0;
}

.sidebarMobile li a:hover:before {
  content: '';
  background: #222aa4;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: 2%;
  width: 7px;
  height: 40px;
  left: 0;
}

.signuploginbtn {
  margin-left: 20px;
}

.fs18 {
  font-size: 20px !important;
  line-height: 28px !important;
}

.signupTandC a {
  color: #00f !important;
  border-bottom: 2px solid #00f;
}

.signupTandC {
  font-weight: 600;
}

.fs16 {
  font-size: 19px !important;
  line-height: 24px !important;
}

.fs22 {
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  margin-top: 8px !important;
}

.fs28 {
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  margin-top: 8px !important;
}

.sidebarMobile .nav li>a>span>div>.caret {
  display: inline-block;
  height: 0;
  width: 0;
  margin-left: 2.255em;
  margin-top: 7px;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  color: #9BA7BF !important;
  position: absolute;
  right: 0;
  margin-right: 15px;
}

.sidebarMobile .rotate-180 {
  transform: rotate(180deg);
}

.new-sidebar-class .sidebar .logo {
  /* box-shadow: none !important; */
  background-color: #fff;
  border-bottom: 1px solid #D0D8EA;
}

.transFrom-180 {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg)
}

.border-right-CCD7EB {
  border-right: 1px solid #CCD7EB;
}

.fw-600 {
  font-weight: 600 !important;
}

.uploadTitle {
  color: black
}

.custom-list-table-row {
  background-color: white;
  border-radius: 10px;
  padding: 12px 16px;
  display: flex;
  line-height: 35px;
  align-items: center;
}

.custom-array-table-row {
  background-color: white;
  border-radius: 10px;
}

.min-height-50 {
  min-height: 50px !important;
}

/* New Sidebar Css */

@media (max-width:1299px) {
  .main-panel .collapse-sidebar-arrow {
    display: none;
  }

  .main-panel .expand-sidebar-arrow {
    display: none;
  }
}

@media (min-width: 1300px) {
  .main-panel .collapse-sidebar-arrow {
    display: block;
    width: 15px;
    height: 20px;
    position: fixed;
    left: 248px;
    top: 80px;
    z-index: 10;
  }

  .main-panel .expand-sidebar-arrow {
    display: block;
    width: 15px;
    height: 20px;
    position: fixed;
    left: 68px;
    top: 70px;
    z-index: 10;
  }
}

/* New Sidebar Css End */

.actionblueicon {
  color: #2550AC;
}

@media (min-width: 1920px) {
  .vacation-box__item {
    max-width: 250px !important;
  }

  .vacation-panel__item {
    max-width: 250px !important;
  }
}

.menuBtn span {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 65px;
  white-space: nowrap;
  font-weight: 600;
}

.languageFlagtoogle {
  visibility: hidden;
  border: none !important;
}

.languageFlagImg {
  visibility: visible;
}

.btn-container {
  display: block
}

@media (width <=420px) {
  .btn-container {
    display: flex;
    flex-direction: column;
  }

  .btn-container>i {
    display: block;
    width: 100% !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: darkorange;
}

.projectassignbtn {
  color: #2550AC;
  font-weight: 600;
}

.projectMemberBadge {
  border-radius: 50% !important;
  background-color: #C8C8C8 !important;
  color: #4A4949 !important;
  padding: 0.25rem !important;
}

.modalAcceptCount {
  color: #2550AC;
  font-weight: 600;
}

.reportsTitle {
  color: #2550AC;
  font-weight: 600;
  font-size: 18px;
}

.reportFooter .btn {
  max-width: 180px;
  min-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panelReports {
  border-radius: 5px;
  border: 1px solid #dddddd !important;
}

.projectMemberForm {
  max-height: 55vh;
  overflow: auto;
  &>table{
    margin-top: 0;
  }
}

.projectMemberForm>table>thead>tr>th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.projectMemberFormMain span {
  white-space: nowrap;
}

.projectMembersFormTable th {
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.absenceReportModalBody {
  overflow-y: scroll;
}

.new-sidebar-class .nav li.active ul li.active {
  background: #E6EAF2;
  position: relative;
}

.new-sidebar-class .nav li.active ul li.active::before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 55px;
  left: 0;
}

.sidebarMobile .nav li.active ul li.active::before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 40px;
  left: 0;
}

.new-sidebar-class .nav li.active .active {
  background: #E6EAF2;
  position: relative;
}

.new-sidebar-class .nav li.active .active::before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 55px;
  left: 0;
}

.sidebarMobile .nav li.active .active::before {
  content: '';
  background: #2550ac;
  border-radius: 0px 56px 56px 0px;
  position: absolute;
  top: -1px;
  width: 7px;
  height: 40px;
  left: 0;
}

.new-sidebar-class .nav li.active .active .sidebarIcon {
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(14);
}

.checklist-assign-card {
  background: #F2F4F9;
  border-radius: 10px;
  max-height: 85vh;
  padding: 20px;
  overflow-y: scroll
}

.checklist-element-divider {
  border-top: 1px solid #B8BECC;
}

.checklist-assign-element-checkbox {
  margin-top: 6px;
  margin-right: 6px;
}

.modal-body-custom__header {
  text-align: start;
  color: #2550AC;
  font-size: 26px;
  font-weight: 600;
}

.checklist-assign-card-element {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.checklist-element-name {
  font-size: 20px;
}

@media (width >=992px) {
  .genericlist_header_xxl_auto>div {
    width: auto !important;
  }

  .genericlist_header_xxl_auto>div:not(:last-child) {
    margin-right: 3px;
  }
}

.textarea-100 textarea {
  min-height: 100px !important;
}

.task_template_view_texts {
  font-weight: 600;
  font-size: 16px;
  color: #2550AC;
}

.task_template_view_sub_texts {
  font-weight: 600;
  font-size: 14px;
}

.text-overflow {
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logout-screen {
  font-size: 5vmin;
  color: #2550AC;
  font-weight: 700;
  margin: 1rem 0 1rem 2rem;
}

@keyframes typing {
  0% {
    content: ".";
  }

  50% {
    content: ". .";
  }

  100% {
    content: ". . .";
  }
}

.typewriter-for-logout::before {
  content: "";
  animation: typing 1.6s infinite;
}

.bg-color-F9FAF5 {
  background-color: #F9FAF5;
}

.color-gray {
  color: gray;
}

.grid-center {
  display: grid;
  place-items: center;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-25 {
  font-size: 25px;
}

.image_icon_size_20 {
  max-width: 20px;
  max-height: 20px;
}

.min-width-100 {
  min-width: 100px !important;
}

.min-height-20 {
  min-height: 20px !important;
}

.delete-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(125, 123, 123, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-text {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
}

.help-block {
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
  color: #2550ac;
  line-height: 1.2;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.custom-customer-separator {
  height: 80%;
  border-left: 3px solid rgb(150, 145, 145);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.custom-customer-header {
  width: 'max-content';
  font-size: 18px;
  color: #2550AC
}

.customer-layout-footer {
  position: sticky;
  bottom: 0;
  color: #fff;
  background-color: #2550AC;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-buttonlike-style {
  background-color: #e0e2e4;
  border-radius: 10px;
  cursor: pointer;
  color: #2550AC;
  display: flex;
  align-items: center;
}

@media (width < 500px) {
  .custom-navbar-wrap {
    display: flex;
    align-items: center;
  }
}