.sign-panel {
    display: flex;
    justify-self: center;
    flex-direction: row;
}

.sign-panel .progresContainer {
    flex-grow: 1;
}

.sign-panel .prepareDocCon {
    height: 100vh;
    overflow: scroll;
}

.singLoading {
    background-color: blue;
}

.stepper_progress div:nth-child(3) {

    border-color: blue;
}

.sign-panel .stepData {
    width: 800px;
    margin: auto;
}

.sign-panel .userList {
    margin-top: 1.3em;
}

.sign-panel .userList .react-select__indicator-separator {
    display: none;
}

.sign-panel .review-panel {
    width: 10%;
}

.prepareBody::-webkit-scrollbar {
    display: none;
}

/* width */
.sign-panel ::-webkit-scrollbar {
    width: 6px;
    display: block;
}

/* Track */
.sign-panel ::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.sign-panel ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #dbe5ed;
}

/* Handle on hover */
.sign-panel ::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb;
}

.sign-panel .widget-panel {
    min-width: 20%;
    border-right: 4px solid white;
    padding: 0px 14px;
    position: relative;
    height: 100vh;
    overflow: scroll;
}

.sign-panel .property-panel {
    width: 15%;
    border-left: 4px solid white;
    height: 100vh;
    overflow-y: scroll;
}

.property-panel .propCon {
    height: 100%;
    width: 100%;
}

.property-panel .propNav {
    padding: 1em;
    border-bottom: 1px solid #dbdbdb;
}

.property-panel .propNav img {
    margin-right: .5em;
    cursor: pointer;
}

.property-panel .propContent {
    background-color: white;
    margin: .4em;
    padding: .6em;
}

.property-panel .propContent .actionbtns {
    display: flex;
    margin-top: .5em;
}

.property-panel .propContent .actionbtns button:first-child {
    margin-right: .8em;
}

.property-panel .propContent input[type=text] {
    width: 100%;
    border: solid 1px #c9c9c9;
    padding: .5em;
    border-radius: 5px;
    margin-bottom: .8em;
}

.property-panel .propContent label {
    margin-top: .8em;
    display: block;
}

.property-panel .propContent .requiredField {
    display: flex;
    align-items: center;
}

.property-panel .propContent .requiredField input {
    margin-right: .4em;
}

.property-panel .propContent .recDropDown {
    margin-bottom: .8em;
}

.property-panel .propContent .propTitle {
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 1.2em;
    display: flex;
    align-items: center;
}

.property-panel .propContent .propTitle>img {
    margin-right: .6em;
}

.widget-panel .widgetHead {
    font-weight: bold;
    margin: 0.9em 0;
}

.fileNameInput {
    min-width: 35%;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    padding: 0.3em;
    font-family: Segoe UI;
    font-size: 16px;
}

.dropzoneContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    border: 1.5px dashed #dbdbdb;
    padding: 3em;
}

.fileNextBtn {
    display: flex;
    justify-content: flex-end;
    margin: 1em;
    padding: 0.5em 1em 1em 0;
}

.main-panel .stepContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepper_progress {
    margin: 13px 170px 20px 170px;
}

.stepper_progress span {
    line-height: 30px !important;
}

.stepper_progress a {
    line-height: 30px !important;
}

.stepper_progress a:nth-child(2) {
    font-size: 14px !important;
    font-weight: bold !important;
    color: black !important;
}

.stepper_progress div:nth-child(2) div {
    font-size: 14px !important;
    font-weight: bolder !important;
    color: black !important;
    border-color: blue;
}


.stepper_progress div:nth-child(3) div {
    font-size: 14px !important;
    font-weight: bolder !important;
    color: black !important;
    border-color: blue;
}

.stepper_progress div:nth-child(4) div {
    font-size: 14px !important;
    font-weight: bolder !important;
    color: black !important;
    border-color: blue;
}

.main-panel .prepareDocNav {
    border-radius: 5px;
    margin-bottom: 2px;
}

.main-panel .stepNav {
    background: rgba(255, 255, 255, 0.96);
    padding: 1.4em 2em;
    border-radius: 5px;
}

.main-panel .stepContent {
    background-color: rgba(255, 255, 255, 0.96);
    padding: 2em;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 2em;
}

.main-panel .prepareDoc {
    border-radius: 0;
    padding: 0;
    margin-bottom: 5em;
}

.RFS-StepperContainer {
    width: 70%;
    justify-content: center;
}

.stepNav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.stepNav .btnNxt button {
    float: right;
}

.stepNav .btnbck button,
.stepNav .btnNxt button {
    padding-right: 2em;
    padding-left: 2em;
}

.RFS-StepMain button {
    border: 1px solid #dbdbdb;
}

.RFS-StepButton.active,
.RFS-StepButton.completed {
    border: 0px;
}

.RFS-StepButton.active+div.RFS-LabelContainer .RFS-Label,
.RFS-StepButton.completed+div.RFS-LabelContainer .RFS-Label {
    font-weight: bold;
}

.stepLbl {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
}

.dropzoneContainer #signUpBtn {
    font-weight: bold;
}

.dropzoneContainer .btnContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.docContainer {
    margin: 2em 0;
}

.docContainer .docTabHead {
    font-weight: bold;
    color: #979797;
    font-size: 14px;
    border-bottom: 1.5px solid #f0f4f7;
    padding-bottom: 0.5em;
}

.docContainer .docRow {
    border-bottom: 1px solid #f0f4f7;
    font-size: 1em;
    padding: 0.6em 1em;
    display: grid;
    grid-template-columns: 40px auto 60px 40px;
}

.docContainer .docRowActive {
    border-bottom: 1px solid #f0f4f7;
    font-size: 1em;
    padding: 0.6em 1em;
    display: grid;
    grid-template-columns: 40px auto 60px 40px;
    background-color: #f9fafc;
}

.docContainer .docRow .saveIcon {
    color: #777777;
    margin-left: 3px;
    font-size: 20px;
}

.docContainer .docRowActive .saveIcon {
    color: #777777;
    margin-left: 3px;
    font-size: 18px;
    margin-left: 8px;
    padding-top: 10px;
}

.justifyEnd {
    justify-self: end;
    -ms-grid-column-align: end;
}

.alingCen {
    align-self: center;
    -ms-grid-column-align: center;
}

.docDel>img {
    cursor: pointer;
}

.docEdit {
    cursor: pointer;
}

.docEdit .edIcon {
    margin-left: 5px;
    display: none;
}

.docEdit:hover .edIcon {
    display: inline-block;
    animation-name: editAnim;
    animation-duration: 0.5s;
}

@keyframes editAnim {
    from {
        transform: translate(-10px, 0);
    }

    to {
        transform: translate(0, 0);
    }
}

.widgetList .widget {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.9em 1em;
    border-bottom: 1px solid #f0f4f7;
    cursor: pointer;
}

.widgetList .widget:last-child {
    border: none;
}

.widgetList {
    background-color: #F9FAF5;
    border-radius: 5px;
}

.widgetList .widget .widgetTitle {
    margin-left: 10px;
}

.sign-panel .pageContainer {
    margin: 1em;
}

.pageContainer .pageImg {
    width: 100%;
    height: 180px;
    background-color: white;
    border: solid 1px #dbdbdb;
}

.pageContainer .pageImg>img {
    width: 100%;
    height: 100%;
}

.pageContainer .pageInfo {
    display: flex;
    justify-content: space-between;
    background-color: #f9fafc;
    padding: 0.3em 0.6em;
    font-size: 14px;
    border: solid 1px #dbdbdb;
}

.pageContainer .pageInfo img {
    cursor: pointer;
}

.prepareDocCon .imgDeivider {
    background-color: #f0f4f7;
    height: 20px;
}

.prepareDocCon .prepareImg>img {
    width: 100%;
}

.prepareDocCon .prepareImg {
    position: relative;
}

.widgetDropIcon {
    position: fixed;
    left: -99999999px;
}

.prepareImg .signWidgetCon>img {
    width: 100%;
    max-height: 100%;

}


.TextboxCon-txt {
    height: 100%;
}

.TextboxCon-txt>textarea {
    height: 100%;
    width: 100%;
    border: 1px solid black;
}

.TextboxCon-date {
    height: 100%;
}

.TextboxCon-date>input {
    height: 100%;
    width: 100%;
    border: 1px solid black;
}

.SelectboxCon {
    height: 100%;
}

.SelectboxCon>.input-sel {
    height: 100%;
    width: 100%;
    border: 1px solid black;
    padding: 0 10px;
}

.CheckboxCon {
    width: 100%;
    height: 100%;
}

.CheckboxCon>.chkBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body_content {
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.body_content .delete_icon {
    width: 55px;
    height: 55px;
    background-color: #f00;
    border-radius: 50%;
    padding: 15px;
}

.delete_text {
    font-size: 22px;
    font-family: Roboto;
    margin: 15px 0px;

}

.modal_header {
    background-color: #f0f4f7 !important;
    border-radius: 8px 8px 0px 0px;
}

.modal_heading {
    font-family: Roboto;
    font-weight: 500;
    font-size: 22px;

}

.modal_delete .modal-content {
    border-radius: 10px !important;
    padding: 0;
}

.modal_delete .modal-dialog {
    border-radius: 10px !important;
    width: 700px !important;
    margin: 30px auto !important;
}

.property-panel .dlt-btn {
    width: 100%;
}

.property-panel .dlt-btn>button {
    margin-top: 1em;
    width: 100%;
}

.historyDoc {
    padding: 30px;
}

.historyDoc h4 {
    font-weight: bolder !important;
    padding-bottom: 15px;
}

.historyDoc p {
    color: grey;
}

.btn1 {
    background-color: grey !important;
    color: white !important;
    margin: 20px;
}

#input {
    font-weight: bold;
    border-radius: 1px;
    background-color: grey;
    /* float:left; */
}

input:checked~#input {
    background: blue;
    color: white;
}

.agreement-modal-body h5 {
    margin: 0;
}

.verification-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-panel .caseListContainer {
    border: none !important;
    background-color: transparent !important;
}

.caseListContainer .search-bar {
    width: 100%;
    display: flex;
    margin-bottom: 1em;
}

.caseListContainer .searchInput {
    position: relative;
    width: 35%;
}

.caseListContainer .searchInput>input {
    border: solid 1px #dbe5ed;
    border-radius: 5px;
}

.caseListContainer .drop-down {
    position: relative;
    width: 25%;
    padding-left: 3%;
}

.caseListContainer .drop-down .react-select__control {
    border-color: #dbe5ed;
}

.caseListContainer .drop-down .react-select__indicator-separator {
    display: none;
}

.caseListContainer .createBtn {
    width: 40%;
}

.caseListContainer .createBtn button {
    height: 40px;
    float: right;
}

.caseListContainer .searchInput .search-icon {
    position: absolute;
    right: 2%;
    top: 20%;
}

.recipientListModal .modal-content {
    padding: 0;
}

.recipientListModal .modal-header {
    background-color: #f0f4f7;
    border-radius: 8px 8px 0px 0px;

}

.recipientListModal .modal-header .modal-title {
    float: left;
}

.recipientListModal .modal-header img {
    float: right;
    cursor: pointer;
    padding-top: 0.4em;
}

.recipientListModal .modal-dialog {
    width: 700px !important;
    margin: 30px auto !important;
}

@media (max-width: 767px) {
    .caseListContainer .search-bar {
        flex-direction: column;
    }

    .caseListContainer .searchInput {
        width: 100%;
        margin-bottom: 1em;
    }

    .caseListContainer .createBtn {
        width: 100%;

        margin-bottom: 1em;
    }

    .caseListContainer .drop-down {
        width: 100%;
        margin-bottom: 1em;
        padding-left: 0;
    }
}

.ReactTable .actions-center {
    padding-top: -10%;
}

.butn {
    display: flex;
    justify-content: center;
}

.text-pdf {
    resize: none;
    border: none;
}

.caseListContainer .dot {
    width: 14px;
    height: 14px;
    margin-right: 0.5em;
    border-radius: 50%;
}

.widget-panel .dot {
    width: 14px;
    height: 14px;
    margin-right: 0.5em;
    border-radius: 50%;
}

.recipient_color_dot {
    width: 14px;
    height: 14px;
    margin-right: 0.5em;
    border-radius: 50%;
}
.SelectboxCon .react-select__control {
    border-radius: 0;
    border: 1px solid black;
    height: 100%;
    background-color: unset;
}

.SelectboxCon .react-select__indicator-separator {
    display: none;
}

.dltc {
    background-color: red;
    padding: .2em;
}

.hover-underline:hover {
    color: #0087ca;
    text-decoration: underline;
    cursor: pointer;
}

/* signature Wrapper */

.signBorderLeft {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 30px;
    border-width: 2px 0 2px 2px;
    border-style: solid;
    border-color: #C0C0C0;
}

.signBorderRight {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30px;
    border-width: 2px 2px 2px 0;
    border-style: solid;
    border-color: #C0C0C0;
}

.signTitle {
    font-size: 50px;
    display: block;
    margin: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    top: -30px;
    font-weight: bold !important;
    color: black
}

.paddingSign {
    padding: 0.5rem !important;
}

.divInlineBlock {
    display: inline-block !important;
    width: 100%;
}

.signWrap {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 7px 0 5px;
    max-width: 100%;
    position: relative;
    padding: 0 30px;
    font-weight: bold !important;
}

.signWrap.signResponsive {
    font-size: 16px;
    margin: 0.513em 0 0.313em;
    padding: 0 1.875em;
}

.signWrap.signInlineBorder:before,
.sign-wrap.signInlineBorder:after {
    display: none;
}

.signWrap .sign {
    font-size: 21px;
    display: inline-block;
    margin: 0 -10px;
    width: 90%;
}

.signWrap .sign img {
    max-width: 100%;
    max-height: 100px;
}

.font-15 {
    font-size: 1.5rem;
}

.signWrap .signUniqueId {
    font-size: 50px;
    display: block;
    margin: 0 4px 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    bottom: -26px;
    font-weight: bold !important;
    letter-spacing: 1px;
    color: black
}

.draft_signdoc_name {
    color: #4c83f7;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}

.draft_signdoc_name:hover {
    color: blue;
}

.not_draft_signdoc_name {
    text-align: center;
    color: #7c7c7c;
    font-size: 12px;
    font-weight: 600;
    cursor: no-drop;
}

.language-dropdown .dropdown-toggle::after{
    display: none;
}
