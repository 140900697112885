:root {
    /* --blue: #1e90ff;
    --white: #ffffff; */
    --main_color: #1b3d81;
    --primary_color: #447df7;
    --secondary_color: #0066cc;
    --main_color: #1b3d81;
    --white_color: #ffffff;
    --heading_underline_bg_color: linear-gradient(90deg, #1b3d81 0%, #ff6699 100%);
    --HR_card_title: linear-gradient(90deg, #1b3d81 0%, #0066ff 100%), linear-gradient(90deg, #1b3d81 0%, #ff6699 100%);
}

.tnc-header {
    display: flex;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    position: fixed;
    z-index: 99999;
    width: 100%;
    background: #1d1d1f;
    top: 0;
}

.tnc-header .branding-logo {
    padding-left: 24px;
    cursor: pointer;
}

.tnc-header .main-avigation {
    flex-grow: 1;
    display: flex;
    color: #99ccff;
    justify-content: center;
    padding: 12px;
    font-weight: 500;
}

.tnc-header .main-avigation .nav-link {
    padding: 6px 16px 5px 16px;
    cursor: pointer;
    letter-spacing: 0.1em;
}
.tnc-header .main-nav-ru .nav-link {
    padding: 6px 11px 5px 16px;
}

.tnc-header .right-nav {
    display: flex;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 500;
}

.activelink {
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-position: under;
    text-decoration-thickness: 3px;
    /* border-bottom: 3px solid var(--main_color) !important; */
    color: white;
    font-weight: 600;
}

.tnc-header .right-nav > div {
    padding: 12px 24px;
}

.tnc-header .right-nav .signup-btn {
    background: #0071e3;
    color: white;
    cursor: pointer;
}

.tnc-header .right-nav .login-btn {
    color: #99ccff;
    cursor: pointer;
   
}

.tnc-header .mobile-login {
    margin-right: 36px;
    color: #99ccff;
}

.tnc-header .mobile-login {
    display: none;
    margin-right: 2.2em;
}
.home-container .home-banner .mobile-mobile-banner-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 16%;
    left: 3%;
}
.home-container .mobile-banner-content .card-container {
    width: 100%;
}
.home-container .mobile-banner-content .card {
    background-color: #1b3d81;
    color: white;
    width: 64%;
    padding: 1em;
    border-radius: 1em;
    float: right;
}
.home-container .mobile-banner-content .card .card-title {
    font-weight: 500;
    font-size: 1.25em;
    color: white;
    margin-bottom: 0.6em;
    font-size: 1.25em;
}
.home-container .mobile-banner-content .feature-card .title-main-card {
    font-weight: 500;
    font-size: 1.25em;
    color: white;
    margin-bottom: 0.6em;
    font-size: 1.25em;
}
.home-container .mobile-banner-content .card .card-txt {
    margin-bottom: 0.8em;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 28px;
}
.home-container .mobile-banner-content .card .appstore {
    margin-right: 1em;
}
.tnc-header .mobile-nav-toggle {
    display: none;
    margin-right: 0.4em;
}
@media (max-width: 992px) {
    .lang-drop .open .dropdown-menu {
        position: absolute;
        background-color: white;
        visibility: visible;
    }
    .lang-drop .dropdown-menu {
        visibility: hidden;
    }
}
.mobile-banner-content {
    display: none;
}
@media (max-width: 1036px) {
    
    .home-container .home-banner .banner-content {
        display: none;
    }
    .tnc-header .main-avigation {
        display: none;
    }
    .tnc-header .lang-drop > li {
        margin-right: 1em;
    }
    .tnc-header .right-nav {
        display: none;
    }

    .tnc-header .branding-logo {
        flex-grow: 1;
        cursor: pointer;
    }

    .tnc-header .mobile-login {
        display: block;
        margin-right: 2.2em;
        color: #99ccff;
    }

    .tnc-header .mobile-nav-toggle {
        display: block;
    }
}

.tnc-nav-modal .modal-dialog {
    margin: 0;
    width: 100%;
}

.tnc-nav-modal .modal-footer {
    padding: 0;
    border: none;
}

.tnc-nav-modal .action-btns {
    display: flex;
}

.tnc-nav-modal .action-btns .signup-btn,
.tnc-nav-modal .action-btns .login-btn {
    padding: 12px;
    cursor: pointer;
}

.tnc-nav-modal .modal-content {
    border: none;
    background-color: #fff;
    border-radius: 0;
}

.tnc-nav-modal .modal-header {
    border: none;
}

.tnc-nav-modal .modal-header .close-btn {
    float: right;
    cursor: pointer;
}

.tnc-nav-modal .action-btns .login-btn {
    flex-grow: 1;
    text-align: center;
    color: #99ccff;
    background-color: #333333;
}

.tnc-nav-modal .action-btns .signup-btn {
    flex-grow: 1;
    text-align: center;
    background: #0071e3;
    color: white;
}

.tnc-nav-modal .mobile-nav {
    color: #99ccff;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    margin: 15px 0px;
}

.tnc-nav-modal .mobile-nav .nav-link {
    padding: 1em 1em;
    cursor: pointer;
}

.tnc-footer {
    background: #1d1d1f;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    width: 100%;
}

.tnc-footer .copyright-text {
    color: white;
    margin-left: 1.5em;
}

.tnc-footer .policiy-container > a {
    color: white;
    text-decoration: unset;
}

.tnc-footer .policiy-container > a {
    color: white;
    text-decoration: unset;
}
.tnc-footer-mobile{
    display: none;
}

.tnc-footer .policiy-container .user-term {
    margin-right: 2em;
    border-bottom: 1px solid white;
}

.tnc-footer .policiy-container .gdpr-des {
    margin-right: 2em;
    border-bottom: 1px solid white;
}

.tnc-footer .policiy-container .policy {
    margin-right: 2em;
    border-bottom: 1px solid white;
}
.home-container .home-banner {
    position: relative;
}

.home-container .home-banner > img {
    width: 100%;
}

.home-container .home-banner .banner-content {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 8%;
    left: 3%;
}
.home-container .home-banner .mobile-banner-content {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 8%;
    left: 3%;
}

.home-container .read-content {
    color: #333333;
    width: 525px;
    margin-left: 2em;
}
.home-container .read-content .app-ultimate {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
}
.home-container .read-content .card {
    background-color: #1b3d81;
}

.home-container .read-content .card {
    background-color: #1b3d81;
}

.home-container .banner-content .card-container {
    width: 100%;
}

.home-container .banner-content .card {
    background-color: #1b3d81;
    color: white;
    width: 72%;
    padding: 1.4em 1.5em;
    border-radius: 1em;
    float: right;
}

.home-container .banner-content .card .card-title {
    font-weight: 500;
    font-size: 28px;
    color: white;
    margin-bottom: 22px;
}

.home-container .banner-content .feature-card .title-main-card {
    font-weight: 500;
    font-size: 28px;
    color: white;
    margin-bottom: 22px;
}

.home-container .banner-content .card .card-txt {
    margin-bottom: 0.8em;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 28px;
}

.home-container .banner-content .card .appstore {
    margin-right: 1em;
}

.home-container .read-content .hr-line {
    border-bottom: 2px solid #1d1d1f;
    width: 100%;
    margin-left: 10px;
}

.home-container .head-border {
    display: flex;
}

.home-container .head-border span {
    font-size: 1.3em;
    color: #1d1d1f;
}

.home-container .read-txt > p {
    margin-top: 1em;
    font-size: 24px;
    line-height: 1.3em;
    font-weight: 400;
    width: 501px;
}

.home-container .read-txt > ul > li {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5em;
    padding-bottom: 0.5em;
}

.home-container .read-title h2 {
    margin-top: 0.2em;
    font-size: 45px;
    color: black;
    font-weight: bold;
    line-height: 47px;
}

/* Utility classes */

.mt-12 {
    margin-top: 12px !important;
}

.mt-18 {
    margin-top: 18px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-55 {
    margin-top: 55px;
}

.mt-72 {
    margin-top: 72px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}
.mt-80 {
    margin-top: 80px;
}
.mt-100 {
    margin-top: 100px;
}
.mt-120{
    margin-top: 140px;
}

.mr-25 {
    margin-right: 25px;
}

.mb-9 {
    margin-bottom: 9px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}
.mb-44 {
    margin-bottom: 44px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-17 {
    margin-bottom: 18px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-160 {
    padding-top: 160px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-130 {
    padding-top: 130px;
}

.pt-90 {
    padding-top: 80px;
}

.pt-20 {
    padding-top: 1.8em;
}

.pt-14 {
    padding-top: 14px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-49 {
    padding-top: 49px;
}

.pl-45 {
    padding-left: 45px;
}

.pl-200 {
    padding-left: 200px;
}

.pt-30 {
    padding-top: 30px;
}

.dashed {
    width: 68px;
    height: 3px;
    background: var(--primary_color);
    margin-top: 25px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-350{
    margin-left: 350px;
}
.dashed-dark {
    width: 68px;
    height: 3px;
    background: var(--main_color);
    margin-top: 25px;
}

.left-container {
    margin: 0px 70px;
}

.right-container {
    margin: 0px 70px;
}

.stores-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* className='white-box' */
.white-box {
    background-color: var(--white_color);
    padding: 8px;
    border-radius: 4px;
}

.test-img {
    max-width: 100%;
    max-height: 100%;
}

.test-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.store-lnks {
    display: flex;
    justify-content: space-between;
}
.store-lnks .lg-gplay {
    display: none;
}

.store-lnks .lg-appstore {
    display: none;
}

.Hr-section .body-content {
    margin-bottom: 4em;
}
.Hr-section .body-content {
    margin-bottom: 4em;
}

.text-justify {
    text-align: justify;
}

.feature-body .store-lnks > a {
    margin-right: 1em;
}

.feature-body .feature-GUI {
    width: 100%;
}

.feature-body .para-heading .high-light {
    background-color: white;
}

.pt-desktop-25 {
    padding-top: 25%;
}
.ml-30 {
    margin-left: 30px;
}

.feature-body .feature-txt {
    background: #ffffff;
    width: 110%;
}

.landing-text{
    background-color: #FFFFFF;
    width: 100%;
}

.feature-body .container-fluid .row .valuable-asset{
    float: right;
    position: absolute;
    right:0;
    margin-top: 100px;
    z-index: 2;

}
.valuable-asset-digitalSign{
    float: right;
    position: absolute;
    top:5%;
    right:45px;
    z-index: 2;   
}
.valuable-asset-communication{
    float: right;
    position: absolute;
    top:5%;
    right:45px;
    z-index: 2;   
}
.valuable-asset-deviation{
    float: right;
    position: absolute;
    top:5%;
    right:0;
    z-index: 2; 
}
.valuable-asset-report{
    float: right;
    position: absolute;
    top:5%;
    right:-30px;
    z-index: 2; 
}
.main-body .container-fluid .row .col-md-12 .valuable-asset{
    float: right;
    position: absolute;
    top:5%;
    right:0;
    z-index: 2;
}
.feature-txt{
    display: flex;
    flex-direction: row;
    height: 250px;
    align-items: center;
    
}
.landing-text{
    display: flex;
    flex-direction: row;
    height: 250px;
    align-items: center;
    
}


.feature-body .feature-txt .dashed {
    background: #1b3d81;
}

.feature-body .feature-txt .para-heading {
    font-size: 24px;
    line-height: 32px;
    color: #1b3d81;
}

/* Utility classes end*/

/* Media Queries */

@media (min-width: 482px) and (max-width: 744px){
   
    .display-744-flex{
        display: flex;
    }
    .plr-23 {
        padding: 0px 23px;
    }
}
@media (min-width: 744px){
    .valuable-asset-digitalSign{
      
        margin-right:0px;
        margin-top: 65px;
    }
}
@media (max-width: 482px) {
    .about-mobile{
        margin-top: 30px !important;
    }
    .form-main-container{
        margin-top: 400px;
    }
    .landing-bg-title{
        font-size: 40px !important;
    }
    .img-set{
       top: 130px;
    }
    .about-dash{
        top: 70px;
    }
    .activelink{
        color: #1b3d81;
        text-decoration: underline;
        text-decoration-color: #1b3d81;
        text-underline-position: under;
        text-decoration-thickness: 3px;
        /* border-bottom: 3px solid var(--main_color) !important; */
        font-weight: 600;
        
    }
    .schedule-section .top-img2 {
        /* margin-top: 200px;
        right: 0; */
        display: none;
    }

    .schedule-section .top-img {
        margin-top: 650px;
        right: 0;
    }
    .visitor-mobile{
        display: none;
    }

    .mt-negative-150 {
        margin-top: -150px;
    }

    .mt-negative-70 {
        margin-top: -70px;
    }
    .ml-60{
        margin-left: 5px !important;
    }
    .comm-title{
        margin-top: 35px !important;
        left: 0 !important;
    }
    .valuable-asset-digitalSign{
        float: right;
        position: absolute;
        top:5%;
        right:-15px;
        z-index: 2;    
    }
    .valuable-asset-communication{
        float: right;
        position: absolute;
     
        right:-15px;
        z-index: 2;   
    }
    .valuable-asset-deviation{
        float: right;
        position: absolute;
        top:5%;
        right:-15px;
        z-index: 2;   
    }
    .valuable-asset-report{
        float: right;
        position: absolute;
        top:16%;
        right:-20px;
        z-index: 2;   
    }
    .body-content .lp-title{
        width: 55px;
        margin-top: -40px;
        font-size: 30px !important;
        line-height: 50px !important;
    }
    .mt-101{
        margin-top: 101px;
    }
    .lang-drop{
        display: none;
    }

    .tnc-lang-container .tnc-languages{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .tnc-lang-container .selected_language{
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        margin-left: 20px;
    }

    .tnc-lang-container .tnc-languages .tnc-lang-item{
        margin-left: 34px;
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    .tnc-lang-container .tnc-languages .activeLang{
        margin-right: 5px;
    }

    .tnc-lang-container .tnc-languages .lang-text{
        margin-left: 5px;
    }

}

@media (min-width: 1200px)
{
    .flex-column-reverse .col-md-7 .row .col-lg-3{
        width: 15%;
    }

    .row .col-md-6 .the-lines .col-lg-3{
        width: 15%;
    }
}
@media (max-width: 767px) {
    .hide-valuable-asset{
        margin-right: 13px;
        margin-top: -100px;
    }
    .stores-link {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .home-container .home-banner .mobile-banner-content {
        display: flex;
    }
    .home-container .home-banner .mobile-banner-content .tnc-info {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        padding-left: 1em;
        
    }

    .home-container .mobile-banner-content .card {
        width: 100%;
    }
    .stores-link div {
        margin-bottom: 20px;
    }
    .home-container .home-banner .mobile-banner-content {
        width: 100%;
        left: 0;
    }
    .image-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .text-center {
        text-align: center;
    }
    .mt-80 {
        margin-top: 40px;
    }
    /* .mt-negative-150{
        margin-top: -150px;
    } */
    .pt-desktop-25 {
        padding-top: 0;
    }

    .mt-mobile-10 {
        margin-top: 10px;
    }
    .mt-mobile-20 {
        margin-top: 20px;
    }

    .ml-mobile-45 {
        margin-left: 45px;
    }
    .ml-60{
        margin-left: -60px;
    }

    .mb-mobile-20 {
        margin-bottom: 20px;
    }
    .mb-mobile-150{
        margin-bottom: 150px;
    }

    .mt-mobile-50 {
        margin-top: 50px;
    }

    .plr-mobile-15 {
        padding: 0px 15px;
    }

    .pt-112 {
        padding-top: 0;
    }

    .pt-180 {
        padding-top: 180px;
    }

    .body-content .bg-title .lp-title {
        /* font-family: 'SF Pro Display'; */
        position: absolute;
        top: 5px;
        left: 20px;
        color: black;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 52px;
        text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-thickness: 5px;
        text-underline-position: under;
        /* text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-style: ; */
        /* text-decoration: ; */
    }
    

    .body-content .communication-title .lp-title {
        /* font-family: 'SF Pro Display'; */
        position: absolute;
        top: 35px;
        left: 20px;
        color: var(--main_color);
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 52px;
        text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-thickness: 5px;
        text-underline-position: under !important;

        /* text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-style: ; */
        /* text-decoration: ; */
    }

    .store-lnks .lg-gplay {
        display: block;
    }

    .store-lnks {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .store-lnks .lg-appstore {
        display: block;
        margin-bottom: 1em;
    }

    .Hr-section .body-content {
        margin-bottom: 1em;
    }

    

    .tnc-footer {
        flex-direction: column;
        align-items: center;
    }

    .store-lnks .gplay {
        display: none;
    }

    .store-lnks .appstore {
        display: none;
    }

    .para-heading {
        /* font-family: 'SF Pro Display'; */
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        color: #1b3d81;
    }

    .body-content .bg-title {
        color: var(--white_color);
        font-style: normal;
        font-weight: 700;
        font-size: 67px;
        line-height: 80px;
    }

    .body-content .communication-title {
        color: var(--white_color);
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 84px;
    }

    .dashed {
        width: 50px;
        height: 3px;
        background: var(--primary_color);
        margin-top: 25px;
    }

    .ml-negative-mobile-250 {
        margin-left: -250px;
    }

    .flex-column-reverse {
        display: flex;
        flex-direction: column-reverse;
    }

    .checklist-whitebox-lg {
        background-color: var(--white_color);
        padding: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: var(--main_color);
    }

    .checklist-whitebox-lg span {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: var(--main_color);
    }

    .checklist-whitebox-lg .dashed-dark {
        display: none !important;
    }

    .body-content-mobile {
        display: block;
    }

    .body-content-web {
        display: none;
    }

    .schedule-section .bg-title {
        color: #f0f4f7;
    }

    .font-set {
        font-size: 28px !important;
    }

    .font-set-2 {
        font-size: 23px !important;
    }

    .about-us-images .cloud5 {
        right: 0 !important;
        position: absolute;
        left: 137px !important;
    }

    .about-us-images .aim {
        z-index: 1;
        position: relative;
        top: 80px;
        right: -119px !important;
        left: 203px;
        height: 730px;
        width: 195.67px;
        height: 244px;
        /* right: -75px; */
        transform: rotate(-2deg);
    }

    .about-us-images .cloud6 {
        position: absolute;
        top: 378px !important;
        z-index: 1;
        right: -16px !important;
        top: 184px !important;
        width: 204.14px;
        height: 205.66px;
        left: 315.78px;
    }

    .about-us-images {
        margin-top: -945px;
    }

    .padding-top-70 {
        padding-top: 70px;
    }

    .margin-top-223 {
        margin-top: 223px;
    }

    .z-index-15 {
        z-index: 15;
    }

    .about-us-images-2 .cloud7 {
        position: absolute;
        left: -103px;
        width: 344.29px;
        height: 289.15px;
        top: 104px;
    }

    .cloudScedule{
        position: absolute;
        left: -103px;
        width: 344.29px;
        height: 289.15px;
        top: 104px;
    }

    .about-us-images-2 {
        position: relative;
        left: 0;
    }

    .about-us-images-2 .calendar-about-us {
        z-index: 1;
        position: relative;
        width: 284.73px;
        height: 263px;
        left: -38px;
        top: 186px;
    }
    .SceduleImg2{
        z-index: 1;
        position: relative;
        width: 284.73px;
        left: 250px;
    }

    .about-us-images-2 .cloud8 {
        position: absolute;
        left: -107px;
        top: 390px !important;
        z-index: 1;
        width: 227.3px;
        height: 121px !important;
    }

    .margin-top-330 {
        margin-top: -330px;
    }

    .padding-top-310 {
        padding-top: 310px;
    }

    .margin-top-310 {
        margin-top: 310px;
    }

    .padding-top-75 {
        padding-top: 75px;
    }

    .about-us-images-3 .cloud9 {
        position: absolute;
        left: 300px;
        width: 484.82000000000005px;
        height: 320.97px;
        top: 130px;
    }

    .about-us-images-3 {
        position: relative;
        left: 0;
    }

    .about-us-images-3 .planet {
        z-index: 10;
        position: relative;
        /* width: 389px; */
        height: 382px;
        top:-130px;
        height: 300.1731872558594px;
        width: 300.8260498046875px;
        left:-140px;
    }
    .Sceduleimg3{
        z-index: 10;
        position: relative;
        /* width: 389px; */
        left: 14px;
        top: 9px;
        height: 501.1731872558594px;
        width: 523.8260498046875px;
    }

    .about-us-images-3 .cloud10 {
        /* right: 50px;
        top: 0 !important;
         z-index: 1;  */
         display: none;
/* 
        position: absolute;
        left:120px;
        width: 484.82000000000005px;
        height: 281.97px;
        top: -220px; */
    }

    .about-us-images-3 .cloud11 {
        position: absolute;
        left:70px;
        top:-120px !important;
        z-index: 1;
        width: 800px;
        height: 200px;
    }
    .cloudScedule2{
        z-index: 0;
        position: absolute;
        right: 0;
        top: 0 !important;
        /* z-index: 1; */
        width: 0px;
        height: 0;
    }

    .extra-pb {
        margin-bottom: -88px;
    }

    .font-set-4 {
        font-size: 24px !important;
    }

    .font-set-5 {
        font-size: 28px !important;
    }

    .margin-bottom-20 {
        margin-bottom: 20px;
    }

    .reason-section .bg-title {
        font-size: 70px;
    }
    .Hr-section .bg-title { 
        font-size: 42px;
        margin-left: -35px;
    }

    .Hr-section .bg-title .tnc-lp-title{
        font-size: 30px;
        width: 80%;
    }
    .close-icon {
        position: absolute;
        top: -15px;
        right: 15px;
    }

    .close-icon .close-img {
        width: 50px;
        height: 40px;
        cursor: pointer;
    }
    .landing-title {
        font-size: 33px !important;
    }
    .mt-950 {
        margin-top: -950px;
    }
    .padding_top_100 {
        padding-top: 100px;
    }
}

@media (min-width: 768px) {
    .pt-22{
        margin-top:-30px;
    }
    .stores-link {
        display: inline-flex;
        /* justify-content: space-between;
        align-items: center; */
    }

    .stores-link div {
        margin-bottom: 20px;
    }
    .text-center {
        text-align: center;
    }

    .body-content .bg-title .lp-title {
        /* font-family: 'SF Pro Display'; */
        position: absolute;
        /* font-size: 2.4em; */
        top: 50px;
        left: 60px;
        color: var(--main_color);
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        /* text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color); */
        border-bottom: 1px solid linear-gradient(90deg, #1B3D81 0%, #0066FF 100%);
        text-decoration-thickness: 5px;
        text-underline-position: under;
    }

    .body-content .communication-title .lp-title {
        /* font-family: 'SF Pro Display'; */
        position: absolute;
        /* font-size: 2.4em; */
        top: 50px;
        left: 60px;
        color: var(--main_color);
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-thickness: 5px;
        text-underline-position: under;
    }

    .para-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #1b3d81;
    }

    .para-header {
        color: black;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
    }

    .feature-body .schedule-sec .para-heading {
        /* font-family: 'SF Pro Display'; */

        font-size: 21px;
    }

    .body-content .bg-title {
        color: var(--white_color);
        font-style: normal;
        font-weight: 700;
        font-size: 6.2em;
        line-height: 102px;
    }

    .body-content .communication-title {
        color: var(--white_color);
        font-style: normal;
        font-weight: 700;
        font-size: 6.2em;
        line-height: 112px;
    }

    .dashed {
        width: 68px;
        height: 3px;
        background: var(--primary_color);
        margin-top: 25px;
    }

    .ml-negative-250 {
        margin-left: -250px;
    }

    .plr-70 {
        padding: 0px 70px;
    }
    .plr-50{
        padding: 0px 50px;
    }
    .plr-100{
        padding: 0px 100px;
    }
    .about-us-images .cloud5 {
        position: absolute;
        top: -36px;
        right: 0;
        width: 651.41px;
    }

    .about-us-images {
        position: relative;
    }

    .about-us-images .aim {
        z-index: 1;
        position: relative;
        top: 7px;
        right: -18px !important;
        left: 80px;
        height: 730px;
        /* right: -75px; */
        transform: rotate(-2deg);
    }

    .about-us-images .cloud6 {
        position: absolute;
        top: 500px !important;
        z-index: 1;
        right: -200px !important;
        /* top: 505px; */
    }

    .about-us-images-2 .cloud7 {
        position: absolute;
        left: 0;
        width: 651.41px;
    }

    .cloudScedule{
        position: absolute;
        left: 0;
        width: 600.41px;
        bottom: 40px;
    }

    .about-us-images-2 {
        position: relative;
        left: 0;
    }

    .about-us-images-2 .calendar-about-us {
        z-index: 1;
        position: relative;
        /* top: 7px;
        right: -18px !important;
        left: 80px; */
        height: 543.0054931640625px;
        width: 528px !important;
        left: 128px;
        top: 190px;
    }
    .SceduleImg2{
        z-index: 1;
        position: relative;
        /* top: 7px;
        right: -18px !important;
        left: 80px; */
      
        left: 10px;
        bottom: 40px;
    }

    .about-us-images-2 .cloud8 {
        position: absolute;
        left: 0;
        top: 625px !important;
        z-index: 1;
    }

    .about-us-images-3 .cloud9 {
        position: absolute;
        left: -70px;
        width: 612.82px;
        height: 330.97px;
        top: 181px;
    }

    .about-us-images-3 {
        position: relative;
        left: 0;
    }

    .about-us-images-3 .planet {
        z-index: 10;
        position: relative;
        width: 389px;
        height: 382px;
        left: 125px;
        top: 193px;
    }
    .Sceduleimg3{
        z-index: 10;
        position: relative;
        
    }
    .about-us-images-3 .cloud10 {
        z-index: 2;
        position: absolute;
        right: 0;
        top: 128px !important;
        /* z-index: 1; */
        width: 203.82000000000005px;
        height: 387.97px;
    }

    .about-us-images-3 .cloud11 {
        position: relative;
        right: 0;
        top: 150px !important;
        z-index: 1;
        width: 659.42px;
        height: 228.51999999999998px;
    }

    .cloudScedule2{
        z-index: 2;
        position: absolute;
        right: 0;
        bottom: 20px;
        /* z-index: 1; */
        width: 700.82000000000005px;
        height: 900.97px;
    }

    .extra-pb {
        padding-bottom: 200px;
    }

    .font-set {
        font-size: 30px;
    }

    .font-set-2 {
        font-size: 17px !important;
    }

    .font-set-3 {
        font-size: 17px !important;
    }

    .padding-top-40 {
        padding-top: 40px;
    }

    .pt-111 {
        padding-top: 111.5;
    }

    .pt-112 {
        padding-top: 112px;
    }
.pt-150{
    padding-top: 150px;
}
    .pt-205 {
        padding-top: 205px;
    }

    .pt-370 {
        padding-top: 370px;
    }

    .checklist-whitebox-lg {
        background-color: var(--white_color);
        padding: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: var(--main_color);
    }

    .checklist-whitebox-lg span {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: var(--main_color);
    }

    .body-content-mobile {
        display: none;
    }

    .body-content-web {
        display: block;
    }

    .schedule-section .bg-title {
        color: #f0f4f7;
    }

    .reason-section .bg-title {
        font-size: 70px;
    }

    .mt-12-web {
        margin-top: 12px;
    }

    .mb_25_web {
        margin-bottom: 25px;
    }

    .close-icon {
        position: absolute;
        top: -15px;
        right: -15px;
    }

    .close-icon .close-img {
        width: 50px;
        height: 40px;
        cursor: pointer;
    }

    .pb-62 {
        padding-bottom: 62px;
    }

    .mb-40 {
        margin-bottom: 40px;
    }
    .mb-80 {
        margin-bottom: 80px;
    }
    .mb-100 {
        margin-bottom: 100px;
    }
    /* .img-set {
        left: 120px;
        top: -64px;
    } */
    
    .Hr-section {
        padding-top: 40px;
    }
    .pb-100 {
        padding-bottom: 100px;
    }
    .dashed-style {
        display: flex;
        justify-content: end;
    }
    .pl-30 {
        padding-left: 30px;
    }
    .chat_img_container{
        position: relative;
    }
    .chat_img_container .chat1_img{
        position: absolute;
        bottom: 150px;
        left: 86px;
    }
    .chat_img_container .chat2_img{
        position: absolute;
        right: -15px;
        bottom: 1px;
    }
}


.vr-container .bg-image {
    background-image: url('./../assets/images/Visitor-Register.png');
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 500px;
    height: 500px;
    padding-left: 0 !important;
}

/* Media Queries end */

.main-body {
    background-color: #f0f4f7;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

/* .body-content {
    height: 100%;
    width: 100%;
    position: relative;
} */

.lp_user_terms .body-content{
    width: 100%;
    position: relative;
}

.body-content .bg-title .title {
    position: absolute;
    font-size: 2.4em;
    top: 50px;
    left: 60px;
    color: var(--main_color);
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-decoration: underline;
    text-decoration-color: var(--heading_underline_bg_color);
    text-decoration-thickness: 5px;
}

.border {
    border: 2px solid red;
}

/* .para-heading {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--primary_color);
} */

.para-subheading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin-bottom: 10px;
}

.para-subcontent {
    /* font-family: 'SF Pro Display'; */
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #333333;
}

.list-heading {
    /* font-family: 'SF Pro Display'; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* color: var(--secondary_color); */
    color: #333333;
}

.list-content .list {
    list-style-type: none;
    /* font-family: 'SF Pro Display'; */
    list-style-image: url('./../assets/images/CheckIcon.svg');
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    color: #333333;
    margin-top: 36px;
    padding-left: 12px;
    text-decoration: underline;
    text-decoration-color: #99cc99;
    text-decoration-thickness: 1px;
    text-underline-position: under;
}

.list-content-digital .list {
    /* font-family: 'SF Pro Display'; */
    font-style: normal;
    font-weight: 900;
    font-size: 19px;
    line-height: 28px;
    color: #99cc99;
    margin-top: 36px;
    padding-left: 12px;
}

.list-content-digital .list span {
    color: #333333;
    border-bottom: 1px solid #99cc99;
    font-weight: 400;
}
.list-container ol {
    font-weight: bolder;
}

.list-content .list span {
    position: relative;
    top: -5px;
}

/* @media (min-width:50px){
    /* .dashed{
        top: 207px;
        left: 24px;
        height: 3px;
        background: var(--primary_color)
    } */
.dashed {
    width: 68px;
    height: 3px;
    background: #1b3d81;
    /* margin-top: 25px; */
}

@media (max-width: 390px) {
    .tnc-footer{
        display: none;
    }
    .tnc-footer-mobile{
        display: block;
        background: #1d1d1f;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 14px;
        width: 100%;
    }
    .tnc-footer-mobile a{
        color: white !important;
        text-decoration: unset;
    }
    .tnc-footer-mobile .copyright-text{
        color: white !important;
        width: 60%;
    }

    .tnc-footer-mobile .tnc-footer-subcontent{
        display: flex;
        justify-content: space-between;
    }
    .tnc-footer-mobile .tnc-footer-subcontent a{
        display: block;
    }
    
    .payrol {
        padding-top: 50px;
    }
   
    .ml-mobile-45 {
        margin-left: 45px;
    }
    .ml-350{
        margin-left: 0;
    }
    .pt-mobile-50 {
        padding-top: 50px;
    }
    .pt-mobile-20 {
        padding-top: 20px;
    }
    .plr-15 {
        padding: 0px 15px;
    }
    .chat_img_container{
        position: relative;
    }
    .chat_img_container img{
       width: 60%;
    }
    .chat_img_container .chat1_img{
        position: absolute;
        bottom: 90px;
        left: 40px;
    }
    .chat_img_container .chat2_img{
        position: absolute;
        right: -53px;
        bottom: 1px;
    }
    
}

.plr-30 {
    padding: 0px 30px;
}

.plr-35 {
    padding: 0px 35px;
}

.deviation-color {
    background-color: white;
}

.deviation-color .bg-title {
    color: #f0f4f7;
}

.reason-section .bg-title {
    color: #FFFFFF;
}

.image {
    max-width: 100%;
}

#communication-header {
    font-size: 90px;
}

.big-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.small-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

/* .form-control {
    height: 44px;
    width: 500px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
} */

/* .form-group input {
    width: 100%;
} */

.lp-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 60px;
    text-decoration: underline;
    text-decoration-color: var(--heading_underline_bg_color);
    text-decoration-thickness: 5px;
    color: #1b3d81;
}

.tnc-lp-title { 
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    margin-top: -50px;
    line-height: 60px;
    text-decoration: underline;
    text-decoration-color: var(--heading_underline_bg_color);
    text-decoration-thickness: 5px;
    color: black;
}

.contact-us-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    padding-top: 10px;
}
.contact-us-text .add-text {
    font-size: 19px;
    font-weight: 400;
}
.contact-us-email {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-decoration: underline;
    text-decoration-color: var(--heading_underline_bg_color);
    text-decoration-thickness: 4px;
    text-underline-position: under;
    color: #1b3d81;
    padding-top: 10px;
}
.schedule-head-txt {
    font-weight: 500;
    line-height: 28px;
    font-size: 20px;
}

.schedule-foot,
.schedule-ul {
    font-weight: 400;
    font-size: 19px;
    line-height: 32px;
    margin-top: 14px;
}
.tnc-high {
    font-weight: bold;
    padding: 0 4px;
}
/* .border-bottom{
    display: inline-block;
    border-bottom: 2px solid #1B3D81;
    width: 100px;
} */

.margin-left {
    margin-left: 195px;
}

.contact-message {
    height: 212px !important;
    left: 0px;
    top: 0px;
    border-radius: 4px;
}

/* .btn-primary {
    background: #0071e3 !important;
    color: white !important;
} */

.red-star {
    color: red;
    padding-left: 3px;
    position: absolute;
}

/* .form-group .form-control {
    width: 500px;
    height: 44px;
} */

.form {
    padding-top: 80px !important;
}

@media (max-width: 767px) {
    .lp-title {
        margin: 0;
        width: 100%;
    }

    .pl-20 {
        padding-left: 20px;
    }

    .form {
        display: block;
        width: 100%;
        margin: 0;
        padding: 10px;
    }

    .feature-txt .dashed {
        display: none !important;
    }

    .image-padding img {
        padding-top: 12px;
    }

    .form-container {
        /* padding-top: 0;
        width: 100%;
        padding-right: 0; */
        padding: 0px 10px;
    }

    /* .form-group .form-control {
        width: 100%;
        height: 44px;
    } */

    .form-container {
        margin-top: -50px;
    }

    .icon-set img {
        padding-left: 10px;
    }

    .contact-us-text {
        margin-left: -12px;
    }

    .contactus-button .padding-bottom {
        height: 44px;
        width: 100%;
        border-radius: 4px;
    }
    .position-absolute {
        position: relative;
    }
    .mt-650 {
        margin-top: 650px;
    }
}

@media (min-width: 768px) {
    .plr-20 {
        padding: 0px 30px;
    }
  
    /* .form-group .form-control {
        width: 100%;
        height: 44px;
    } */

    .mb-70 {
        margin-bottom: 70px;
    }

    .mt-65{
        margin-top: -65px;
    }
    .icons {
        text-align: end;
        padding-top: 7px;
    }

    .text-set {
        padding-bottom: 20px;
    }

    .text-set-1 {
        padding-bottom: 65px;
    }

    /* .padding-bottom{
        margin-bottom: 100px
    } */
    .form-check-label a {
        text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-thickness: 2px;
        text-underline-position: under;
        color: #3fc1e2;
    }
    .form-check-label2 a {
        text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-thickness: 2px;
        text-underline-position: under;
        color: blue;
    }

    /* .form-group input {
        margin-top: -10px;
    }
    .form-group textarea {
        margin-top: -10px;
    } */
}

/* Visitor Registration */
.form-container-1 {
    background-color: white;
    padding: 24px;
    /* width: 500px */
}

.register {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #1d1d1f;
}

.visitor-text {
    padding-left: 100px !important;
    padding-top: 69px;
    width: 560px;
}

.visitor-text-2 {
    padding-left: 221px !important;
    padding-top: 36px;
}

.visitor-page-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #1d1d1f;
    line-height: 32px;
    width: 468px;
}
.form-container-2 .row .col-md-6{
    width: 50%;
}

.col-md-12 .form-container-2 .visitor-page-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #1d1d1f;
    line-height: 32px;
    width: auto;
}
.form-main-container {
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #e2e3e4;
    box-sizing: border-box;
    border-radius: 8px;
    left: 944px;
    top: 180px;
    display: flex;
}

.form-container-2 {
    padding-top: 24px;
    width: 100%;
}

/* .flex-input{
    display: inline;
} */

.visitor-form-input .visitor-control {
    height: 44px;
    width: 452px;
    border-radius: 4px;
}

.visitor-form-input .visitor-main-control {
    height: 44px;
    width: 100%;
    border-radius: 4px;
}

.visitor-form-input .visitor-main-control-1 {
    height: 44px;
    width: 100%;
    border-radius: 4px;
}

.form-group .st {
    width: 100%;
}

.visitor-form-padding {
    margin-top: -30px;
}

/* .form-group input {
    width: 446px;
    height: 44px;
} */

.padding-top {
    padding-top: 15px;
}

.button button {
    /* height: 44px;
    width: 452px !important; */
    border-radius: 4px;
    width: 100%;
    padding: 8px 0px;
}

.contact-us-text .line-height {
    line-height: 32px;
}

.business-name input {
    height: 44px;
    width: 242px;
    border-radius: 4px;
}

.bg-title .visitor-title {
    position: absolute;
    top: 50px;
    left: 25px;
    color: var(--main_color);
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 60px;
    text-decoration: underline;
    text-decoration-color: var(--heading_underline_bg_color);
    text-decoration-thickness: 5px;
    text-underline-position: under;
}

.body-content .visitor-bg-title {
    color: var(--white_color);
    font-style: normal;
    font-weight: 700;
    font-size: 6.5em;
    line-height: 112px;
}

.visitor-bg-title .visitor-title {
    position: absolute;
    top: 50px;
    left: 25px;
    color: var(--main_color);
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 60px;
    text-decoration: underline;
    text-decoration-color: var(--heading_underline_bg_color);
    text-decoration-thickness: 5px;
    text-underline-position: under;
}

.bg-images {
    padding-left: 0;
}

.padding-top-1 {
    padding-top: 15px;
}

.body-contents .bg-titles {
    color: #F0F4F7;
    font-style: normal;
    font-weight: 700;
    font-size: 67px;
    line-height: 84px;
}

@media (max-width: 767px) {
    .visitor-text {
        padding-left: 50px !important;
        width: 100% !important;
    }

    .visitor-text-2 {
        padding-left: 50px !important;
        width: 100% !important;
    }

    .form-main-container {
        width: 100% !important;
        
    }

    .visitor-form-padding {
        margin-top: -450px;
        padding-left: 0;
    }

    .visitor-page-text {
        /* width: 360px; */
        width: 100%;
        padding-left: 10px;
    }

    /* .form-group .form-control {
        width: 100%;
        height: 44px;
        padding: 0px 10px;
        display: inline-block;
    } */

    .form {
        display: block;
        max-width: 100%;
        height: auto;
        padding-left: 0;
    }

    /* .form-group .control { */
        /* width: 100% !important; */
        /* height: 44px;        
        width: 330px;
        border-radius: 4px;
    } */

    /* .business-id{
        height: 0;
    } */
    .col-md-7 .bg-image {
        padding-left: 0;
    }

    .bg-images {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .padding-top-1 {
        padding-top: 0;
    }

    .visitor-bg-title .visitor-title {
        font-size: 40px;
    }

    .body-content .visitor-bg-title {
        font-size: 6.4em;
    }

    .button button {
        /* height: 44px;
        width: 330px !important; */
        border-radius: 4px;
        width: 100%;
        padding: 8px 0px;
    }

    .col-md-5 .form-visitor {
        display: block;
        width: 100%;
        margin: 0;
        padding: -2px;
        margin-top: -450px;
        padding-left: 0;
    }

    .extra-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .visitor-form {
        padding: 0;
    }

    .main-container {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    .padding-left-30 {
        padding-left: 10px;
    }

    .padding-bottom-50 {
        padding-bottom: 50px;
    }
}

.list-content .list .list-white-box {
    background-color: var(--white_color) !important;
    position: relative;
    top: 2px;
    left: 4px;
    padding: 0px 7px;
    border-radius: 4px;
    text-decoration: underline !important;
    text-decoration-color: #f0f4f7 !important;
    text-decoration-thickness: 0px !important;
    /* text-underline-position: under !important; */
}

.banner-content .tnc-info {
    display: flex;
    align-items: flex-start;
}
.mobile-banner-content .tnc-info {
    display: flex;

}
.Hr-section {
    position: relative;
}

.Hr-section .card {
    display: flex;
    border-radius: 0.5em;
    position: relative;
    justify-content: space-between;
}
.Hr-sections .feature-card {
    display: flex;
    border-radius: 0.5em;
    position: relative;
    justify-content: space-between;
}


.Hr-sections .feature-card .btn-feature {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    background-color: #0066ff;
    padding: 0.2em 0.6em;
    border: none;
    color: white;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 0.6875em;
}
.Hr-sections .feature-card .feature-btn {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    background-color: #0066ff;
    padding: 0.2em 0.6em;
    border: none;
    color: white;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 0.6875em;
}

.Hr-section .card .demo-btn {
    border: none;
    background: #447df7;
    color: white;
    padding: 0.8em 1.3em;
    border-radius: 4px;
    margin-top: 2em;
    font-size: 0.6875em;
    text-transform: uppercase;
}

.Hr-sections .feature-card .ask-demo {
    border: none;
    background: #447df7;
    color: white;
    padding: 0.8em 1.3em;
    border-radius: 4px;
    margin-top: 2em;
    font-size: 0.6875em;
    text-transform: uppercase;
}
.Hr-section .more-feature-btn {
    font-size: 0.875em;
    float: right;
    padding: 1em;
    text-transform: uppercase;
}

.Hr-sections .more-feature-button {
    font-size: 0.875em;
    float: right;
    padding: 1em;
    text-transform: uppercase;
}

.Hr-section .card-content {
    padding: 1.5em 0 1.5em 1.5em;
    border-radius: 0.5em;
}
.Hr-sections .content-cards{
    padding: 1.5em 0 1.5em 1.5em;
    border-radius: 0.5em;
}

.Hr-section .card .card-title {
    font-size: 1.375em;
    text-transform: uppercase;
    margin-bottom: 1em;
    font-weight: 500;
    color: #1b3d81;
    text-decoration: underline;
    text-decoration-color: var(--HR_card_title);
}

.Hr-sections .feature-card .title-main-card {
    font-size: 1.375em;
    text-transform: uppercase;
    margin-bottom: 1em;
    font-weight: 500;
    color: #1b3d81;
    text-decoration: underline;
    text-decoration-color: var(--HR_card_title);
}


.Hr-section .card .card-p > div {
    font-size: 1.1875em;
    line-height: 28px;
    color: #1b3d81;
    font-weight: 400;
}

.Hr-sections .feature-card .card-pa > div {
    font-size: 1.1875em;
    line-height: 28px;
    color: #1b3d81;
    font-weight: 400;
}

.row .col-md-12 .schedule-ul div{
    display: flex;
    padding-left: 10px;
}
.row .col-md-12 .schedule-ul img{
    padding-right: 20px;
}
@media (max-width: 768px) {

    .home-container .card {
        width: 100%;
    }

    .home-container .home-banner .banner-content {
        display: none;
    }

    .padding-digital-sign {
        padding: 0px !important;
    }

    .home-container .banner-content .card {
        width: 100%;
    }

    .banner-content .tnc-info .read-content {
        display: none;
    }

    .home-container .read-txt > p {
        font-size: 20px;
    }

    .home-container .read-title h2 {
        margin-top: 0.5em;
        font-size: 35px;
    }

    .home-container .read-txt > p {
        font-size: 14px;
        line-height: unset;
    }

    .home-container .read-content {
        width: 100%;
        margin-left: 0;
        padding-top: 1em;
    }

    .home-container .tc-logo {
        width: 20%;
        margin-right: 1em;
    }

    .banner-content .tnc-info {
        justify-content: flex-start;
        margin: 1em 1em;
    }

    .Hr-section {
        margin-top: 220px;
    }

    .Hr-sections {
        margin-top: 220px;
    }

    /* .Hr-section .card .card-img {
        display: none;
    } */

    /* .Hr-sections .feature-card .image-card {
        display: none;
    } */

    .Hr-section .card-content {
        padding: 1.5em;
    }

    .image-card{
        display: flex;
        align-items: self-end;
    }
    .Hr-sections .content-cards{
        padding: 1.5em;
    }

    .Hr-sections .feature-card{
        display: flex;
        height: 300px;
    }
    .Hr-sections .feature-card .image-card{
        float: right;
    }
    .Hr-section .card .demo-btn {
        width: 100%;
    }

    .Hr-sections .feature-card .ask-demo {
        width: 100%;
    }

    .Hr-section .more-feature-btn {
        width: 100%;
    }

    .Hr-sections .more-feature-button {
        width: 100%;
    }
}

.schedule-section {
    position: relative;
    background-color: white;
}

.schedule-section .schedule-img > img {
    width: 100%;
}

.schedule-section .schedule-content {
    margin-top: 2em;
}

.schedule-section > img {
    position: absolute;
}

.schedule-section .bottom-img {
    bottom: 5em;
}

.schedule-section .top-img {
    top: 1em;
    right: 0;
}

.schedule-section .top-img2 {
    top: 12em;
    right: 0;
}

.schedule-section .bottom-img2 {
    top: 34em;
    left: 35em;
}

.schedule-section .middle-img {
    top: 20em;
    left: 50em;
}

.reason-section {
    padding-bottom: 3em;
    background-color: #F0F4F7;
}

.feature-body .brand-title,
.reason-section .brand-title {
    margin-top: 1em;
    color: #333333;
    font-weight: bold;
    margin-bottom: 1em;
    line-height: 28px;
    font-size: 19px;
}

.feature-body .brand-logo,
.reason-section .brand-logo {
    display: flex;
    flex-wrap: wrap;
}

.feature-body .brand-logo > img,
.reason-section .brand-logo > img {
    padding: 0 0.5em 0.5em 0;
}

.reason-section .int-brand .btn-fill {
    padding-right: 1.2em;
    padding-left: 1.2em;
    text-transform: uppercase;
}

.feature-body .construct-imgs {
    display: flex;
    justify-content: center;
    margin: 4em 0;
}

.feature-body .construct-imgs > img {
    width: 100%;
    padding: 5px;
}

@media (max-width: 768px) {
    .schedule-section .schedule-content {
        padding-top: 15em;
    }

    .schedule-section .schedule-img > img {
        width: unset;
    }

    .feature-body .brand-logo > img,
    .reason-section .brand-logo > img {
        width: 96px;
        height: 56px;
    }

    .reason-section .int-brand .btn-fill {
        width: 72%;
    }

    .feature-body .construct-imgs {
        flex-wrap: wrap;
    }

    .mb-50 {
        margin-bottom: 50px;
    }
}

@media (min-width: 768px) and (max-width: 1016px) {
    .home-container .home-banner .mobile-banner-content {
        top: 3%;
    }

    .home-container .read-txt > p {
        line-height: unset;
    }
}

.login-container .login-input {
    width: 100% !important;
}
/* .business-name{
    margin-top: -25px;
} */
/* feature page  */

.feature-body {
    position: relative;
}

.feature-body .bg-image {
    position: absolute;
    top: 0;
    right: 0;
}

.activeIndicate {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    background: #ffffff;
    border: 1px solid #e2e3e4;
    padding: 3px;
    display: inline-block;
    margin: 0.5em;
}

.activeIndicate .activeCircle {
    width: 16px;
    height: 16px;
    background: #1b3d81;
    border-radius: 50%;
}

.card-content .card-link {
    cursor: pointer;
    text-decoration: underline;
}

.content-cards .link-card {
    cursor: pointer;
    text-decoration: underline;
}
.card-content .card-title {
    cursor: pointer;
    text-decoration: underline;
}

.card-content .title-main-card {
    cursor: pointer;
    text-decoration: underline;
}

.content-cards .title-main-card {
    cursor: pointer;
    text-decoration: underline;
}
.displayAllFeature {
    display: block;
}
.hideFeature {
    display: none;
}

.body-content{
    margin-top: 50px;
    left: 60px;
}
.terms-content{
    margin-left: 4.2em;
}

.terms-content .pageno{
    color: #C4C4C4;
    font-size: 14px;
    float:right;
}

.example::-webkit-scrollbar {
    display: none;
}

.example {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.lp_user_terms .terms-content{
    margin-left: 8.5em;
    margin-top: 1em;
}
.lp_user_terms .terms-use{
    box-sizing: border-box;
    height: auto;
    margin: 1em;
    background: #FFFFFF;
    border: 1px solid #E2E3E4;
    border-radius: 8px;
    padding: 1.3em;
    font-size: 20px;
}

.col-md-6 .terms-content{
    margin-left: 8.5em;
    margin-top: 1em;
}
.col-md-6 .terms-use{
    box-sizing: border-box;
    height: auto;
    margin: 1em;
    background: #FFFFFF;
    border: 1px solid #E2E3E4;
    border-radius: 8px;
    padding: 1.3em;
    font-size: 20px;
}

.terms-use .notice {
    background: #F2F2F2;
    border-radius: 4px;
    padding: 15px;
    font-size: 17px;
}

.notice .notice-items{
    display: flex;
    flex-direction: column;
}
.notice .notice-items > div{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: .5em;
} 
.notice .notice-items > div > p{
    margin-top: 0;
    padding-left: 1em;
    font-size: .8em;
}

.notice h5{ 
    font-size: 15px;
    line-height: 1.5em;
}

.terms-content .terms{
    font-size: 1.7em;
    text-transform: initial;
    font-weight: bold;
}

.terms-content li{
    padding: .3em;
}

.index-title h6{
    font-size: 0.4em;
}

.list-items p{
    margin: 10px 0;
}

.list-items p a{
    color:black;
}
.col-md-6 .terms-use p{
    font-size: .9em;
}

.terms-content .download-gdpr{
    display: flex;
    flex-direction: row;
}

.terms-content .download-gdpr .download-img{
    float: right;
}

.terms-use .list-item > ol > li{
    font-size: .8em;
    margin: 10px 0;
    text-align: justify;
}

.terms-use .list-item p{
    font-size: .8em;
    margin: 10px 0;
    text-align: justify;
}

.terms-use .list-items > ol > li{
    list-style-type: decimal-leading-zero;
    font-size: .8em;
    margin: 10px 0;
    text-align: justify;
}
.terms-use ol p{
    text-align: justify;
}
.terms-use ul{
    text-align: justify;
    margin-right: 15px;
}
.terms-use ul li { 
    font-size: .8em;
    margin: 10px 0; 
}

.terms-use .list-items p{
    text-align: justify;
}

.terms-use .list-items ol > li {
    margin: 10px 0;
}
.terms-use .list-items ol > li::marker {
    color: #D6D6D6;
    font-weight: bold;
}

.terms-use  ol > li::marker {
    color: #D6D6D6;
}
.terms-use .title-text{
    display: flex;
    justify-content: space-between;
}
.terms-content .part-titles{
    font-weight: normal;
    margin-left: 15px;
}
.terms-use-part1{
    margin-top: 10px;
}
.terms-content .index-title{
    font-size: 40px;
}

.terms-content ul li a{
    color: black;
    font-size: 1.1em;
}

.row .img-texts p{
    color:#1B3D81;
    font-size: 16px;
}
.row .img-texts li{
    color:#1B3D81;
    font-size: 13px;
}

.col-md-6 .terms-use h4{
    font-weight: bolder;
    text-transform: uppercase;
    margin-top: 0;

}
.terms-use h5{
    margin-bottom: -15px;
}

.the-image-texts .valuable-asset{
    float: right;
    position: absolute;
    top:5%;
    margin-right: -15px;
    right:0;
    z-index: 2;   
}

.hr-title{
    color: #f0f4f7;
}

.feature-card{
        border-radius: 4px;
        background-color: #ffffff;
        margin-bottom: 30px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        overflow: hidden;
}
.another-part { 
    margin-top: 10em;
}



@media (max-width: 667px){
    
    .tnc-footer .policiy-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .tnc-btn-design .tnc-btn-control .my-btn{
        /* margin-left: 22px; */
        margin-top: -20px;
    }
    .container-fluid .row .input-control{
        width: 90%;
        justify-content: center;
    }
    .part-1 .body-content .bg-title{
        font-size: 4em;
        margin-left: 50px;

    }
    .bg-title .lp-title{
        /* width: 100%; */
        /* margin-top: 30px; */
    }
    .dashed-text .dashed{
        margin-left: 5px;
    }
    .tnc-footer .policiy-container .user-term{
        margin-right: 0;
        border-bottom: 1px solid white;
    }

    .body-content .visitor-bg-title .visitor-title{
        width: 100%;
    }
    .terms-content .pageno {
        color: #C4C4C4;
        font-size: 14px;
        padding-left: 1em;
        float: none;
    }
    .part-1 .body-content{
        margin-left: -50px;
    }
    .part-1 .terms-content{
        margin-left: 0;
    }
    .terms-use h1{
        font-size: x-large;
    }
    .terms-use .list-items p{
        text-align: left;
        font-size: .8em;
    }  
    .terms-use .list-item > ol > li{
        font-size: .8em;
        margin: 10px 0;
        text-align: left;
    }
    .terms-use .list-item p{
        font-size: .8em;
        margin: 10px 0;
        text-align: left;
    }
    .terms-use .list-items .notice{
        font-size: .8em;
        margin: 10px 0;
        text-align: left;
    }
    .main-visitor .body-content{
        margin-left: -50px;
    }

    .note-list ol li{
        text-align: left;
        font-size: .8em;
        margin: 10px 0;
        margin-left: -30px;
    }
    .terms-use .list-items h4{
        font-size: .8em;
        margin: 10px 0;
        text-align: left;
    }
    .terms-use .list-items > ol > li{
        font-size: .8em;
        margin: 10px 0;
        text-align: left;
    }

    .terms-use ol p{
        text-align: left;
    }
    .terms-use ul{
        text-align: left;
        margin-right: 15px;
    }
}
@media (max-width: 667px){
    .heading{
        padding-top: 0;
    }
    .main-body .container-fluid .body-content{
        left: 0;
    }
    .para-class .col-md-9{
        left: 50px;
    }
    .row .col-md-7 .mt-negative-150{
        padding-top: 140px;
    }
    .row .col-md-7  .feature-timeregister{
        padding-top: 160px;
    }
    .feature-txt{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px;
        padding: 20px;
        height: fit-content;
    }
    .headings-texts{
        margin-top: 750px;
    }
    .vr-container .container-fluid .row .main-visitor .body-content{
        margin-left: 20px;
    }

    .para-texts .para-subheading p{
        width: 70%;
        margin-left: 12px;
    }

    .para-texts .para-subcontent .list-content .list{
        width: 60%;
        margin-left: 0;
        font-size: 16px !important;
    }
    .para-texts .store-links{
        display: flex;
        flex-direction: column;
        margin-left: 35px;
    }
    .download-tnc-texts {
        display: none;
    }

    .the-image-texts .row{
        margin-left: 25px;
    }
    .Hr-section .body-content .parent-title{
        font-size: 42px;
        margin-left: -35px;
    }
    .Hr-section .body-content .parent-title .tnc-lp-title {
        font-size: 25px;
        margin-left: 20px;
        width: 70%;
        text-decoration-color: #1b3d81;
    }
    .schedule-content .container-fluid .row{
        margin-top: -120px;
    }

    .schedule-title .bg-title{
        font-size: 42px;
        margin-left: -50px;
    }
    .Hr-sections .body-contents .bg-titles{
        font-size: 42px;
        margin-top: 30px; 
    }
    .Hr-sections .feature-card .btn-feature{
        font-size: 14px;
        background-color:#1B3D81;
    }
    .feature-card .content-cards{
        margin-top: 22px;
    }
    .img-texts{
        margin-left: -30px;
    }

    .tnc-lp-title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        margin-top: -50px;
        line-height: 60px;
        text-decoration: underline;
        text-decoration-color: var(--heading_underline_bg_color);
        text-decoration-thickness: 5px;
        color: black;
    }

    .schedule-title .lp-title{
        font-size: 25px;
        margin-left: -20px;
        width: 80%;
        text-decoration-color: #1b3d81;
    }
    .app-download-btns .appstore-btn, .gplaystore-btn {
        display: none;
    }
    .app-download-btns .presentation-btn{
        width: 100%;
        height: 80px;
    }
    .reason-section .bg-title{
        font-size: 45px;
        margin-left: -35px;
        width: 90%;
    }
    .reason-section .bg-title .lp-title{
        font-size: 30px;
        margin-left: -35px;
        width: inherit;
        margin-top: 40px;
    }
    .tandc-business{
        margin-left: 30px;
        display: contents;
    }
    .tnc-info .text-contents{
        margin-top: -32px ;
    }
    .read-content .read-content-texts ul li{
        margin-left: -15px;
    }
    .read-content-texts h5{
        margin-left: 0!important;
        font-weight: normal!important;
        font-size: 22px;
        line-height: 30px;
    }
    .store-links a{
        width: 100%;
    }
    .store-links .gplay{
        margin-top: 10px;
    }
    .part-1 .privacy-titles .title{
        margin-left: -50px;
    }
    .Hr-sections .feature-card .card-pa > div {
        font-size: 1.2em;
        line-height: 20px;
        color: #1b3d81;
        font-weight: 400;
    }
    .Hr-sections .feature-card .title-main-card {
        font-size: 1.8em;
    }
    .body-contents .bg-titles {
        margin-left: 10px;
        width: 100%;
    }
    .tnc-footer-mobile .user-term {
        margin-right: 2em;
        border-bottom: 1px solid white;
    }
    
    .tnc-footer-mobile .gdpr-des {
        margin-right: 2em;
        border-bottom: 1px solid white;
    }
    
    .tnc-footer-mobile .policy {
        margin-right: 2em;
        border-bottom: 1px solid white;
    }
    .communication-img{
        margin-left: -118px;
    }
    .body-content .gdpr-body-title{
        margin-left: 0px;
    }
    .part-1 .privacy-titles{
        margin-left: 20px;
    }
    .body-content .gdpr-body-title .gdpr-sub-title{
        margin-left: -30px;
    }
    .another-part{
        margin-top: 1em;
    }
    .emp-guidance{
        margin-top: 1px;
    }
    .timeregister-imgg{
        margin-top: 10px;
    }
    
}
@media only screen and (min-width: 483px) and (max-width: 767px)
{
    .visitor-register-page{
        display: flex;
        flex-direction: row;
    }   
    .visitor-register-page .vs-register-1{
        width: 50%;
    }
}

.display_____flex{
    display: flex;
}
