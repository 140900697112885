.m-table {
  display: flex;
  color: #ddd;
}

.mon-tab-list {
  width: 100%;
  margin-bottom: 2em;
  margin-top: 3em;
}

.m-table .m-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.m-table .m-column .m-block {
  width: 100px;
  min-height: 55px;
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #ddd;
  padding: 0.3em;

}

.m-table .m-column input {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
}

.m-table .list-column .m-block {
  width: 200px;
}

.m-table .list-column .m-block {
  justify-content: flex-start;
}

.tab-comment {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tab-comment>div {
  width: 95%;
  padding: 1em 0;

}

.tab-comment textarea {
  border: 1px solid #ddd;
}

.mot-action {
  display: flex;
  justify-content: center;
}

.mot-action button {
  border: none;
  color: white;
  padding: 0.2em 0.4em;
  margin: 0 0.1em;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.mot-action .add-tab {
  background-color: #447df7;
}

.mot-action .minus-tab {
  background-color: red;
}

#mo-comment {
  width: 100%;
  border-radius: 5px;
}

.monitor-form-data {
  margin-left: 20px;
}

.monitor-form-row1 {
  margin-left: -30px;
  margin-top: 10px;
}

.monitor-form-row2 {
  margin-left: -30px;
  margin-top: 40px;
}

.monitor-form-row2 img {
  height: 28px;
  width: auto;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.monitor-form-data .monitor-title h5 {
  color: #1783ff;

}

.monitor-form-row1 .add-monitor .input-block__base-input {
  margin-top: -10px;
  height: 40px;
}

.monitor-form-row2 .input-block__base-input {
  margin-top: -6px;
  height: 40px;
  width: 222px;
}

.btn-monitor .btn {
  border-width: 1px;
  background-color: transparent;
  font-weight: 400;
  opacity: 0.8;
  filter: alpha(opacity=80);
  padding: 6px 16px;
  border-color: #447df7;
  color: #447df7;
  margin-top: 30px;
  margin-left: 20px;
}

.sub-history-container .date-filter {
  background-color: #f8f8f8;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 12px 9px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
  color: #777;
  margin-bottom: 30px;
}

.monitoring-history-container .history-title {
  color: #1783ff;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: space-between;
}

.monitoring-history-container,
.monitoring-grid-container {
  margin: 0 15px;
}

.sub-history-container .table-content {
  margin-top: 30px;
  margin-bottom: 20px;
}

.sub-history-container .table-content .filter-table {
  /* border-collapse: collapse; */
  width: 70%;
}



.sub-history-container .table-content .filter-table,
.table-content td,
.table-content th {
  border: 1px solid #ddd;
  text-align: center;
  padding: 12px !important;
}

.sub-history-container .table-content th {
  padding: 15px;
}

.sub-history-container .table-content td {
  padding: 15px;
}

.monitoring-grid-container .grid-title {
  color: #1783ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.monitoring-grid-container .grid-title .btn {
  height: fit-content;
  width: 100px;
}

.monitoring-grid-container .grid-title h5 {
  color: #1783ff;
}

.monitoring-grid-container .save-monitoring {
  position: absolute;
  bottom: 6em;
  right: 40px;
}

.monitoring-grid-container .grid-save-btn {
  float: 'right';
  bottom: "10px";
}

#m-block-even {
  border-bottom: 0;
  border-right: 0;
}

#m-block-even:last-child {
  border-bottom: 1px solid #ddd;
}

#m-block-odd:last-child {
  border-right: 1px solid #ddd;
}

#m-table-end:last-child {
  border-right: 1px solid #ddd;
}

#m-block-odd {
  border-bottom: 0;
  border-right: 0;
}

.monitoring-table-container {
  margin-bottom: -10px;
}

.monitoring-table-container .monitoring-table td,
.monitoring-table-container .monitoring-table th {
  text-align: center !important;
}

.m-column .m-block .itemList {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-data-found p {
  position: absolute;
  margin-top: 5.5em;
  margin-left: 26em;
}

.text__align {
  text-align: center;
}

.m-grid-extra-style {
  height: 18px;
  overflow: 'hidden';
  width: 75px;
  white-space: 'nowrap';
  text-overflow: 'ellipsis';
}

.margin__right {
  margin-right: 10px;
}

.monitor_filter_width_240 {
  width: 240px;
}

@media print {
  .myDiv {
    page-break-inside: avoid;
  }

  .beforePage {
    page-break-before: always;
  }

  .afterPage {
    page-break-after: always;
  }
}

.mon-tab-list input::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mon-tab-list input[type=number]{
  -moz-appearance: textfield;
}