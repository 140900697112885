.table-project-small{
  tbody{
    .table-project-small__tasklink {
      border-radius: 5px;
      background: #a9b7bf;
    }
    .table-project-small__row{
      transition: 0.3s ease-in-out;
      cursor: pointer;
      a{
        color: #333333;
        font-weight: 900;
        display: inline-flex;
        width: 100%;
        justify-content: center;
        &:hover{
          text-decoration: underline;
        }
      }
      &:hover{
        background-color: #c5d4f5;
      }
      &_active{
        color: #ffffff;
        background-color: #447df7!important;
        a{
          color: #ffffff;
        }
      }
    }
  }
}

// Schedules
.scheduler{
  & > tbody{
    tr{
      border: none;
      td{
        border: none;
        .resource-view{
          position: relative;
          border: none;
          overflow: hidden;
          div{
            border: none;
            overflow: hidden;
            .resource-table{
              thead{
                border-left: 2px solid #e9e9e9!important;
                border-bottom: 2px solid #e9e9e9!important;
                border-top: 2px solid #e9e9e9!important;
              }
              .resource-row{
                background-color: #ffffff;
                border-left: 2px solid #e9e9e9!important;
                border-bottom: 2px solid #e9e9e9!important;
                span.slot-text{
                  color: #777777;
                }
                a.slot-text{
                  color: #447df7;
                  cursor: pointer;
                  &:hover{
                    text-decoration: underline;
                  }
                }
                &.pending-resource{
                  background-color: #F2F5FB;
                  span.slot-text{
                    color: #777777;
                  }
                  a.slot-text{
                    color: #777777;
                    cursor: auto;
                    &:hover{
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
        .scheduler-view{
          border: none;
          & > div{
            .scheduler-content{
              .scheduler-content-table{
                tbody{
                  tr{
                    .pending-row{
                      border-left: 2px solid #e9e9e9;
                    }
                    .event-container{
                      cursor: pointer;
                      .timeline-event{
                        margin-top: 0px;
                        .event-item{
                          background-color: #447df7!important;
                          font-size: 11px!important;
                          border-radius: 20px!important;
                          border-left: 0!important;
                          padding: 0 5px!important;
                          text-align: center;
                          transition: opacity 0.3s ease-in-out;
                          span{
                            margin: 0!important;
                          }
                          &:hover{
                            opacity: 0.8;
                          }
                        }
                      }
                    }
                    .add-more-button{
                      .btn{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        padding: 0;
                        color: black;
                        background-color: #fff;
                        border-color: black;
                        i{
                          font-size: 12px;
                        }
                      }
                    }
                    &:last-child{
                      outline: none;
                    }
                  }
                }
              }
            }
            border: none;
            // Table Header Right
            &:first-child{
              & > div{
                padding-bottom: 17px;
              }
              .scheduler-bg-table{
                thead{
                  border-top: 2px solid #e9e9e9 !important;
                  border-right: 2px solid #e9e9e9 !important;
                }
                thead > tr > th {
                  font-size: 12px;
                  font-weight: 400;
                  outline: 1px solid #e9e9e9;
                }
                thead > tr > td {
                  border-right: 1px solid #e9e9e9 !important;
                  border-bottom: 1px solid #e9e9e9 !important;
                }
              }
            }
          }
        }
        .scheduler-bg-table{
          tr > td {
            outline: 1px solid #e9e9e9;
          }
          .tr-0 td {
            background: #00bbff;
          }
        }
      }
    }
  }
  &-popup{
    max-width: 300px;
    &__header{
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      div{
        min-width: 10px;
        max-width: 10px;
        min-height: 10px;
        max-height: 10px;
        margin-right: 5px;
      }
      span{
        color: #447df7;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__content{
      padding-top: 5px;
      border-top: 1px solid #e9e9e9;
      text-align: center;
      font-size: 14px;
      color: #9A9A9A;
    }
    &__contentText{
      padding-top: 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }
}

// Control panel tables
.cp-table{
  border-collapse: separate;
  border-spacing: 0 0.5em;
  margin-top: 0px !important;
  .cp-input{
    height: 36px;
    border-color: #447df7;
    border-radius: 10px;
    padding: 10px 16px;
    background-color: #F9FAF5;
    outline: none !important;
    border: 0;
    &:focus{
      border-color: #447df7 !important;
      border-radius: 10px;
      border: transparent;
    }
  }
  tr{
    th{
      font-size: 14px !important;
    }
    &:hover{
      background-color: inherit !important;
    }
  }
  td{
    font-size: 14px !important;
    &:first-child{
      padding-left:20px;
      padding-right:0;
      padding-top:15px;
      padding-bottom:15px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
    &:last-child{
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

.custom-card{
  .list-group{
    margin-top: 10px;
  }
  .tab-content{
    .tab-pane{
      padding: 0px !important;
    }
  }
}


